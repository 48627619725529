// Animation for Decision Tree
.dt-animate {
  &-enter {
    visibility: hidden;
    opacity: 0;

    &-active {
      opacity: 1;
      visibility: visible;
      transition: opacity 500ms;
      -webkit-transition: opacity 500ms;
      -moz-transition: opacity 500ms;

      &-done {
        visibility: visible;
      }
    }
  }

  &-exit {
    opacity: 1;
    visibility: visible;

    &-active {
      opacity: 0;
      transition: opacity 500ms;
      -webkit-transition: opacity 500ms;
      -moz-transition: opacity 500ms;

      &-done {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}
