@import '../../../../../scss/colors';
@import '../../../../../scss/mixins';

.data-block {
  $block: &;
  width: 100%;
  border-radius: 8px;

  &--tiny {
    max-width: 320px;
  }
  &--xxs,
  &--xs {
    max-width: 335px;
  }
  &--s {
    max-width: 100%;
  }
  &--m {
    max-width: 740px;
  }
  &--l {
    max-width: 520px;
  }
  &--xl {
    max-width: 740px;
  }

  &--linear-gauge {
    width: 100%;
    max-width: 740px;
  }

  &__container {
    // @include frostedGlass(extra-light);
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 64px 44px 44px;
    height: 100%;
    backdrop-filter: blur(7.5px);
    border-radius: 8px;

    #{$block}--tiny & {
      padding: 28px 10px 10px;
    }
    #{$block}--xxs & {
      padding: 28px 20px;
    }
    #{$block}--xxs#{$block}--bar-alt & {
      padding-top: 44px;
    }
    #{$block}--xs & {
      padding: 40px 20px 20px;
    }
    #{$block}--xs#{$block}--bar-alt & {
      padding-top: 44px;
    }
    #{$block}--m & {
      padding: 64px 44px 44px;
    }
    #{$block}--linear-gauge & {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      padding: 36px;
    }
    #{$block}--tiny#{$block}--linear-gauge &,
    #{$block}--xxs#{$block}--linear-gauge &,
    #{$block}--xs#{$block}--linear-gauge & {
      padding: 20px;
    }
    @include respond-below(md){
      padding: 40px 20px;
    }
  }

  &__title-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;

    #{$block}--tiny & {
      gap: 15px;
    }
    #{$block}--xxs &,
    #{$block}--xs & {
      gap: 20px;
    }

    #{$block}--xs & {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    #{$block}--xs#{$block}--bubble & {
      max-width: 90%;
    }

    #{$block}--s#{$block}--bubble & {
      max-width: 75%;
    }

    #{$block}--m#{$block}--doughnut & {
      position: absolute;
      top: 64px;
      left: 44px;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
    #{$block}--m#{$block}--bubble & {
      max-width: 65%;
    }

    #{$block}--l#{$block}--bubble & {
      max-width: 85%;
    }

    #{$block}--xl#{$block}--bubble & {
      max-width: 75%;
    }

    #{$block}--line & {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }

    #{$block}--bubble & {
      max-width: 85%;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }

  &__title {
    @include headline(5);
    color: $ts-container-on-container-secondary-1;
    margin-bottom: 0;
    max-width: 100%;

    #{$block}--tiny &,
    #{$block}--xxs & {
      @include headline(small);
      max-width: 80%;
    }

    #{$block}--xs & {
      max-width: 95%;
    }
    #{$block}--xs#{$block}--bar & {
      @include headline(medium);
    }

    #{$block}--s#{$block}--doughnut & {
      max-width: 100%;
    }

    #{$block}--m & {
      @include headline(4);
      max-width: 100%
    }
    #{$block}--m#{$block}--doughnut & {
      // max-width: 250px;
    }

    #{$block}--l & {
      @include headline(3);
    }
    #{$block}--l#{$block}--bar-alt &,
    #{$block}--l#{$block}--doughnut & {
      @include headline(5);
    }

    #{$block}--xl & {
      @include headline(3);
      max-width: 95%;
    }
    #{$block}--xl#{$block}--doughnut & {
      max-width: 70%;
    }
  }

  &__desc-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-shrink: 1; //0;

    #{$block}--s#{$block}--doughnut & {
      position: absolute;
      top: 54px;
      max-width: 150px;
    }
    #{$block}--m#{$block}--doughnut & {
      max-width: 200px;
    }
    #{$block}--xl#{$block}--doughnut & {
      position: absolute;
      top: 126px;
      max-width: 220px;
    }
  }

  &__label {
    @include label(small);
    margin: 0;
    padding-bottom: 0;
    color: $ts-container-on-container-secondary-2;

    #{$block}--xl & {
      @include label(medium);
    }
  }

  &__text {
    @include headline(small);
    margin: 0;
    color: $ts-container-on-container-secondary-1;

    #{$block}--tiny#{$block}--bar-alt &,
    #{$block}--xxs#{$block}--bar-alt & {
      @include label(medium);
    }

    #{$block}--xs & {
      @include headline(medium);
    }
    #{$block}--xl#{$block}--doughnut & {
      @include headline(4);
    }
  }

  &__chart-container {
    width: 100%;

    #{$block}--bar & {
      padding-top: 10px;
    }
    #{$block}--l#{$block}--bar & {
      padding-top: 0;
    }
  }

  &__fallback {
    @include label(small);
    color: $ts-container-on-container-secondary-2;
  }
  max-width: 100%;
}