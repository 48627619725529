@import '../../../../scss/colors';
@import '../../../../scss/mixins';

.no-scroll {
  overflow: hidden;
}

.decision-tree {
  $block: &;

  .docs-story & {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 32px;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    padding: 20px 20px 32px;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(20px);
    overflow-y: auto;
    z-index: 1000;

    @include respond-above(md) {
      gap: 34px;
      padding: 40px;
    }

    .docs-story & {
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }

  &__container {
    position: relative;
    flex: 1;
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background: $ts-background-primary;
    border-radius: 8px;

    @include respond-above(md) {
      padding: 60px;
    }

    #{$block}--2-1 & {
      padding: 0;

      @include respond-above(md) {
        padding: 0;
      }
    }
  }

  &__footer {
    width: 100%;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }

  &__up-arrow,
  &__right-arrow {
    display: none;

    @include respond-above(md) {
      display: block;
      position: absolute;
    }
  }

  &__up-arrow {
    top: 0;
    right: 0;
  }

  &__right-arrow {
    left: 0;
    bottom: 0;
  }
}
