@import '../../../../scss/colors';
@import '../../../../scss/mixins';

.dt-step1 {
  position: relative;
  z-index: 1;

  &__container {
    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 28px;

    @include respond-above(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__btn-container {
    display: flex;
    justify-content: flex-end;

    @include respond-above(md) {
      order: 1;
    }
  }

  &__title-container {
    @include respond-above(md) {
      order: 0;
      background: radial-gradient($ts-background-primary, transparent);
    }
  }

  &__sub-title {
    @include label(small);
    margin-bottom: 10px;
    color: $ts-background-on-primary-2;

    @include respond-above(md) {
      @include label(medium);
    }
  }

  &__title {
    @include headline(medium);
    margin-bottom: 32px;
    color: $ts-background-on-primary-1;

    @include respond-above(md) {
      @include headline(3);
      margin-bottom: 40px;
    }
  }

  &__card-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include respond-above(md) {
      flex-direction: row;
    }
  }
}
