/* INTER */

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./../src/static/fonts/Inter-Light.woff2') format('woff2'),
    url('./../src/static/fonts/Inter-Light.woff') format('woff'),
    url('./../src/static/fonts/Inter-Light.otf') format('otf'),
    url('./../src/static/fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('./../src/static/fonts/Inter-LightItalic.woff2') format('woff2'),
    url('./../src/static/fonts/Inter-LightItalic.woff') format('woff'),
    url('./../src/static/fonts/Inter-LightItalic.otf') format('otf'),
    url('./../src/static/fonts/Inter-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./../src/static/fonts/Inter-Regular.woff2') format('woff2'),
    url('./../src/static/fonts/Inter-Regular.woff') format('woff'),
    url('./../src/static/fonts/Inter-Regular.otf') format('otf'),
    url('./../src/static/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('./../src/static/fonts/Inter-Italic.woff2') format('woff2'),
    url('./../src/static/fonts/Inter-Italic.woff') format('woff'),
    url('./../src/static/fonts/Inter-Italic.otf') format('otf'),
    url('./../src/static/fonts/Inter-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./../src/static/fonts/Inter-Medium.woff2') format('woff2'),
    url('./../src/static/fonts/Inter-Medium.woff') format('woff'),
    url('./../src/static/fonts/Inter-Medium.otf') format('otf'),
    url('./../src/static/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('../src//static/fonts/Inter-MediumItalic.woff2') format('woff2'),
    url('../src/static/fonts/Inter-MediumItalic.woff') format('woff'),
    url('../src/static/fonts/Inter-MediumItalic.otf') format('otf');
}

// @font-face {
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
//   src: url('./../src/static/fonts/Inter-SemiBold.woff2') format('woff2'),
//     url('./../src/static/fonts/Inter-SemiBold.woff') format('woff'),
//     url('./../src/static/fonts/Inter-SemiBold.otf') format('otf'),
//     url('./../src/static/fonts/Inter-SemiBold.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Inter';
//   font-style: italic;
//   font-weight: 600;
//   font-display: swap;
//   src: url('./../src/static/fonts/Inter-SemiBoldItalic.woff2') format('woff2'),
//     url('./../src/static/fonts/Inter-SemiBoldItalic.woff') format('woff'),
//     url('./../src/static/fonts/Inter-SemiBoldItalic.otf') format('otf'),
//     url('./../src/static/fonts/Inter-SemiBoldItalic.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
//   src: url('./../src/static/fonts/Inter-Bold.woff2') format('woff2'),
//     url('./../src/static/fonts/Inter-Bold.woff') format('woff'),
//     url('./../src/static/fonts/Inter-Bold.otf') format('otf'),
//     url('./../src/static/fonts/Inter-Bold.ttf') format('truetype');
// }

// @font-face {
//   font-family: 'Inter';
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
//   src: url('./../src/static/fonts/Inter-BoldItalic.woff2') format('woff2'),
//     url('./../src/static/fonts/Inter-BoldItalic.woff') format('woff'),
//     url('./../src/static/fonts/Inter-BoldItalic.otf') format('otf'),
//     url('./../src/static/fonts/Inter-BoldItalic.ttf') format('truetype');
// }

/*

EXTRA FONT WEIGHTS NOT CURRENTLY USED

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url("/fonts/Inter-Thin.woff2") format("woff2"), url("/fonts/Inter-Thin.woff") format("woff"),
        url("/fonts/Inter-Thin.otf") format("otf"), url("/fonts/Inter-Thin.ttf") format("truetype");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url("/fonts/Inter-ThinItalic.woff2") format("woff2"), url("/fonts/Inter-ThinItalic.woff") format("woff"),
        url("/fonts/Inter-ThinItalic.otf") format("otf"), url("/fonts/Inter-ThinItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("/fonts/Inter-ExtraLight.woff2") format("woff2"), url("/fonts/Inter-ExtraLight.woff") format("woff"),
        url("/fonts/Inter-ExtraLight.otf") format("otf"), url("/fonts/Inter-ExtraLight.ttf") format("truetype");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url("/fonts/Inter-ExtraLightItalic.woff2") format("woff2"),
        url("/fonts/Inter-ExtraLightItalic.woff") format("woff"), url("/fonts/Inter-ExtraLightItalic.otf") format("otf"),
        url("/fonts/Inter-ExtraLightItalic.ttf") format("truetype");
}


@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("/fonts/Inter-ExtraBold.woff2") format("woff2"), url("/fonts/Inter-ExtraBold.woff") format("woff"),
        url("/fonts/Inter-ExtraBold.otf") format("otf"), url("/fonts/Inter-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url("/fonts/Inter-ExtraBoldItalic.woff2") format("woff2"),
        url("/fonts/Inter-ExtraBoldItalic.woff") format("woff"), url("/fonts/Inter-ExtraBoldItalic.otf") format("otf"),
        url("/fonts/Inter-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("/fonts/Inter-Black.woff2") format("woff2"), url("/fonts/Inter-Black.woff") format("woff"),
        url("/fonts/Inter-Black.otf") format("otf"), url("/fonts/Inter-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url("/fonts/Inter-BlackItalic.woff2") format("woff2"), url("/fonts/Inter-BlackItalic.woff") format("woff"),
        url("/fonts/Inter-BlackItalic.otf") format("otf"), url("/fonts/Inter-BlackItalic.ttf") format("truetype");
}
*/
