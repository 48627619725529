@import '../../../../scss/colors';
@import '../../../../scss/mixins';

$small-padding: 20px;
$large-padding: 60px;

.dt-step3 {
  $block: &;
  position: relative;
  z-index: 1;

  &__container {
    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 28px;

    @include respond-above(md) {
      gap: 83px;
    }
  }

  &__btn-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 32px;

    @include respond-above(md) {
      justify-content: flex-start;
      align-items: center;
      gap: 70px;

      .back-button {
        order: 0;
      }

      .dt-breadcrumb {
        order: 1;
      }

      .close-button {
        order: 2;
        margin-left: auto;
      }
    }
  }

  &__title-container {
    @include respond-above(md) {
      background: radial-gradient($ts-background-primary, transparent);
    }
  }

  &__sub-title {
    @include label(small);
    margin-bottom: 10px;
    color: $ts-background-on-primary-2;

    @include respond-above(md) {
      margin-bottom: 12px;
    }
  }

  &__title {
    @include headline(4);
    margin-bottom: 32px;
    color: $ts-background-on-primary-1;

    @include respond-above(md) {
      @include headline(3);
      margin-bottom: 44px;
    }
  }

  &__card-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include respond-above(md) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(238px, auto));

      &--lg-cols {
        grid-template-columns: repeat(auto-fit, minmax(301px, auto));
      }
    }
  }
}
