@import '../../../scss/mixins';
@import '../../../scss/colors';
@import '../../../scss/fonts';
@import '../../../../scss/variables';

// Manpower Theme Accordions Components Overrides
.accordion-section-advanced {
  padding-top: 40px;
  .title {
    //font-family: $font-helvetica-med;
    font-size: 3rem;
    font-weight: 400;
    @include mobile {
      font-size: 2rem;
    }
  }
  p {
    font-size: 1.25rem;
    color: $color-darkgray;
    margin: 20px 0 50px 0;
    @include mobile {
      font-size: 1rem;
      margin: 20px 0 30px 0;
    }
  }
  .accordion-block {
    position: relative;
    text-align: left;
    border-bottom: 0.0625rem solid $ts-color-gray-50;
    padding: 0.75rem 1.25rem;
    &.active {
      .icon {
        top: 0.25rem;
        &:after {
          transform: rotate(180deg);
        }
      }
      .accordion-body {
        display: block;
      }
      .accordion-content {
        display: none;
        margin: 1rem;
        padding: 0 40px 0 20px;
      }
      &.active {
        .accordion-content {
          display: block;
          margin: 0;
          padding: 4px 20px 40px 20px;
        }
      }
    }
    &:first-child {
      border-top: 0.0625rem solid $ts-color-gray-50;
    }
    .icon {
      position: absolute;
      font-size: 1.95rem;
      right: 1.25rem;
      top: 0;
      cursor: pointer;
      @include respond-above(md) {
        right: -15px;
      }
      &:after {
        display: block;
        content: '';
        background-image: url('../../../static/icons/icon--arrow-down.svg');
        background-size: 1rem;
        background-repeat: no-repeat;
        width: 1rem;
        height: 1rem;
        margin-top: 1.25rem;
        @include respond-above(md) {
          background-size: 1.125rem;
          width: 1.125rem;
          height: 1.125rem;
          margin-top: 1.125rem;
        }
      }
    }
    .accordion-title {
      padding: 0;
      margin: 0;
      cursor: pointer;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem;
      letter-spacing: -0.00625rem;
    }
    .accordion-body {
      display: none;
      margin-top: 1rem;
      * {
        margin: 0;
        padding: 0;
        margin-bottom: 1rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      h1,
      h2,
      h3,
      h4 {
        @include headline(small);
      }
      h5 {
        @include headline(small-bold);
      }
      h6 {
        @include headline(uppercase);
      }
      ul,
      ol {
        padding-left: 2rem;
      }
    }
  }
  .accordion-block {
    .accordion-title {
      //font-family: $font-helvetica-med;
      font-size: 1.563rem;
      font-weight: 400;
      //color: $color-darkgray;
      padding: 0 0px;
      cursor: pointer;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.5rem;
      letter-spacing: -0.00625rem;
      font-family: $font-inter;

      @include mobile {
        font-size: 1.125rem;
        line-height: 1.2;
        padding: 0 40px 0 20px;
      }
    }
    p {
      padding: 20px 30px;
      margin: 0;
      @include mobile {
        padding: 20px 20px 10px 20px;
      }
    }
    .icon {
      background: none;
      border: none;
      &:after {
        content: '';
        position: relative;
        bottom: 2px;
        right: 30px;
        margin-left: 20px;
        background-image: url('../../../static/icons/icon--arrow-down.svg');
        background-size: 16px 10px;
        display: inline-block;
        width: 16px;
        height: 10px;
        transition: all 200ms ease-in-out;
      }
      @include mobile {
        font-size: 0.875rem;
        margin: 5px 0 30px 0;
        right: -12px;
      }
    }
    .accordion-content {
      margin: 1rem;
      display: none;
    }
    &.active {
      .accordion-content {
        display: block;
        margin: 1rem;
      }
    }
  }
  &.gradient {
    background-color: $pale-grey;
  }
  .primary-button {
    display: none;
  }
  .accordion-content {
    .row {
      @include mobile {
        padding-top: 12px;
      }
      .country-list {
        padding: 0.75rem;

        @include mobile {
          min-width: min-content;
          margin-top: -12px;
          margin-bottom: 1rem;
          padding: 12px;
        }
        &:empty {
          display: none;
        }
      }
    }
    .countries {
      display: inline;
      font-size: 1.125rem;
      list-style: none;
      padding-left: 32px;
      margin-bottom: 0;
      padding: 0 !important;
      .country-name {
        font-family: Inter;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem; /* 100% */
      }

      li {
        word-break: break-word;
        display: inline;
        span {
          color: $color-darkgray;
          padding-right: 5px;
          font-size: 1.125rem;
        }
        .country-language {
          color: $mpg-color-violet-75;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: -0.32px;
          margin-right: 0.25rem;
        }
      }
      a {
        padding: 0 5px;
      }
      &:empty {
        display: none;
      }
    }
  }
  button {
    outline: none !important;
    &:focus-visible {
      outline: 5px auto !important;
    }
  }
}

span.country-name {
  color: $color-lightgray;
}
