@import '../../../scss/colors';

.calloutbar-section.contact.globalContact {
  padding: 5rem 0;
  @media (min-width: 992px) {
    .content-wrapper {
      .title__contact {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 26px;
        letter-spacing: -1px;
      }
      .spacer {
        padding-bottom: 1.5rem;
      }
      .divider {
        border: 0;
        border-bottom: 0.0625rem solid $color-lightgray;
        margin: 0 0 2.5rem 0;
        padding: 0 0 2.5rem 0;
      }
    }
  }
}
