@import '../../../../scss/colors';
@import '../../../../scss/fonts';
@import '../../../../scss/mixins';

.rich-text-block {
  background: unset;
  padding: 3.75rem 5rem 0;
  @include mobile {
    padding: 3.75rem 1.25rem 0;
  }
  &.gray {
    background-color: $pale-grey;
  }
  &.light {
    background-color: $white;
  }
  .text-header {
    text-align: center;
    margin: 0 auto 1.5rem;
    &.small {
      max-width: 32rem;
    }
    .title {
      margin: 0 auto 1.5rem;
    }
    p {
      margin: 0;
    }
  }
  .content {
    p {
      text-align: left;
      margin: 0 0 1.5rem;
      &:last-child {
        margin: 0;
      }
    }
    ul {
      margin: 0 0 1rem 0;
      li {
        text-align: left;
        margin: 0;
        padding: 0;
        p:last-child {
          text-align: left;
          margin: 0 0 1.5rem;
        }
      }
    }
  }
}
.utility-typography {
  .rich-text {
    div {
      h2 {
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.625rem;
        letter-spacing: -1px;
      }
      h3 {
        margin-bottom: 12px;
        line-height: 44px;
        font-size: 2.5rem;
        @include mobile {
          font-size: 1.5rem;
          line-height: 30px;
        }
      }
      div {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
      }
    }
  }
}
.utility-block {
  a {
    color: #5c4bb9;
  }
}
.tagsblocktitle {
  font-size: 2rem !important;
}

