$black: #000000;
$white: #FFFFFF;
$white-faded: #F3F3F4;
$pale-grey: #EFEFEF;
$warm-grey: #DEDEDE;

$color-blue: #386097;
$color-lightblue: #4C79AF;
$color-orange: #C25700;
$color-teal: #5C7D70;
$green: #5C7D70;
$color-green: #5C7D70;
$color-medium-green: #5C7D70;
$color-lightgreen: #D6DEDB;
$color-maroon: #9D323D;
$color-grayblue: #B1C7E2;

$color-lightgray: #67696F;
$color-gray-subtitle: #67696f;
$color-darkgray: #282a32;
$text-body: #282A32;
$color-footergray: #939498;

$color-light-body: #A3A5AA;
$color-sidebar-item: #424242;
$color-light-border: #C9CACC; 
$color-dark-border: #ABABAB; 
$color-indicator: #83858B;


/* For Use w Gradients ONLY */
$color-darkblue: #1C3E6B;
$color-darkgreen: #355C4C;

/* DNU | Inverse Dark Colors - Dark Cards */
$color-inverse-blue: #7593BC;
$color-inverse-maroon: #B5656D;

/* All Brand Colors */

//BLUE
$color-blue-100: #386097;
$color-blue-75: #6A88B1;
$color-blue-50: #9BAFCB;
$color-blue-25: #CDD7E5;

//LIGHT BLUE
$color-lightblue-100: #4C79AF;
$color-lightblue-75: #799AC3;
$color-lightblue-50: #A5BCD7;
$color-lightblue-25: #D2DDEB;

// ORANGE
$color-orange-100: #C25700;
$color-orange-75: #D18140;
$color-orange-50: #E0AB80;
$color-orange-25: #F0D5BF;

// GREEN
$color-green-100: #5C7D70;
$color-green-75: #859D94;
$color-green-50: #ADBEB7;
$color-green-25: #D6DEDB;

// LIGHT GRAY
$color-lightgray-100: #67696F;
$color-lightgray-75: #8D8F93;
$color-lightgray-50: #B3B4B7;
$color-lightgray-25: #D9D9DB;

// DARK GRAY
$color-darkgray-100: #282A32;
$color-darkgray-75: #5E5F65; // TODO update #5E6066 in Sitecore;
$color-darkgray-50: #939498;
$color-darkgray-25: #C9CACC;

// RED
$color-red-100: #9D323D;
$color-red-75: #B5656D;
$color-red-50: #CE989E;
$color-red-25: #E6CCCE;

/* 
 * NEW ManpowerGroup Colors 
 */

/* ORANGE */
$mpg-color-orange-100: #b43700;
$mpg-color-orange-75: #c25700; // OG Orange
$mpg-color-orange-50: #e0690a;
$mpg-color-orange-25: #ff7a12;
$mpg-color-orange-00: #FF9442; // used for reversed orange hover

/* RED */
$mpg-color-red-100: #9d323d; // OG Red
$mpg-color-red-75: #cc334d;
$mpg-color-red-50: #e4415f;
$mpg-color-red-25: #fc5c7d;

/* VIOLET */
$mpg-color-violet-100: #4f4296;
$mpg-color-violet-75: #5c4bb9; 
$mpg-color-violet-50: #736bde; 
$mpg-color-violet-25: #9e94fa; 

/* GREEN */
$mpg-color-green-100: #5c7d70; // OG Green
$mpg-color-green-75: #669e66;
$mpg-color-green-50: #70bf5c;
$mpg-color-green-25: #a6eb8c;

/* BLUE */
$mpg-color-blue-100: #386097; // OG Blue
$mpg-color-blue-75: #4c79af; // OG Light Blue
$mpg-color-blue-50: #3893CD;
$mpg-color-blue-25: #56BDED;

/* GRAY */
$mpg-color-gray-100: #282a32; // OG Dark Gray
$mpg-color-gray-75: #67696f;  // OG Light Gray
$mpg-color-gray-50: #939498;
$mpg-color-gray-25: #EFEFEF; // OG Pale Grey


/* 2 COLOR GRADIENTS */
$linear-gradient-blue: $mpg-color-blue-100, $mpg-color-blue-25;
$linear-gradient-green: $mpg-color-green-100, $mpg-color-green-25;
$linear-gradient-violet: $mpg-color-violet-100, $mpg-color-violet-25;
$linear-gradient-red: $mpg-color-red-100, $mpg-color-red-25;
$linear-gradient-orange: $mpg-color-orange-100, $mpg-color-orange-25;

/* MULTI COLOR GRADIENTS */
$linear-gradient-blue-violet: $mpg-color-blue-50, $mpg-color-violet-75, $mpg-color-violet-50;
$linear-gradient-orange-red: $mpg-color-orange-25, $mpg-color-red-25, $mpg-color-red-50;
$linear-gradient-green-blue: $mpg-color-green-50, $mpg-color-blue-50, $mpg-color-blue-100;

$ui-gradient-primary: $linear-gradient-green-blue;
$ui-gradient-secondary: $linear-gradient-blue-violet;


/* MPG UI CONFIG */
$ui-color-text-default: $mpg-color-gray-100;
$ui-color-heading-default: $mpg-color-gray-100;
$ui-color-heading-blue: $mpg-color-blue-100;

$ui-primary-button-color: $mpg-color-orange-50;
$ui-primary-button-color-hover: $mpg-color-orange-75;

$ui-secondary-button-color: $mpg-color-blue-50;
$ui-secondary-button-color-hover: $mpg-color-blue-75;

$ui-bg-color-light: $white;
$ui-bg-color-gray: $mpg-color-gray-25;
$ui-bg-color-dark: $mpg-color-gray-100;
$ui-bg-color-orange: $mpg-color-orange-50;
$ui-bg-color-blue: $mpg-color-blue-50;
$ui-bg-color-green: $mpg-color-green-50;
$ui-bg-color-violet: $mpg-color-violet-50;

$ui-form-color-success: $mpg-color-green-75;
$ui-form-color-error: $mpg-color-red-100;
$ui-form-bg-color-error: rgba(230, 204, 206, 0.75);


/* TALENT SOLUTIONS color-palette */
$ts-color-blue-100: #386097;
$ts-color-blue-75: #4c79af;
$ts-color-blue-50: #3893cd;
$ts-color-blue-25: #56bded;
$ts-color-green-100: #5c7d70;
$ts-color-green-75: #669e66;
$ts-color-green-50: #70bf5c;
$ts-color-green-25: #a6eb8c;
$ts-color-red-100: #9d323d;
$ts-color-red-75: #cc334d;
$ts-color-red-50: #e4415f;
$ts-color-red-25: #fc5c7d;
$ts-color-orange-100: #b43700;
$ts-color-orange-75: #c25700;
$ts-color-orange-50: #e0690f;
$ts-color-orange-25: #ff7a12;
$ts-color-violet-100: #4f4296;
$ts-color-violet-75: #5c4bb9;
$ts-color-violet-50: #736bde;
$ts-color-violet-25: #9e94fa;
$ts-color-black: #000000;
$ts-color-gray-100: #282a32;
$ts-color-gray-75: #67696f;
$ts-color-gray-50: #939498;
$ts-color-gray-25: #efefef;
$ts-color-white: #ffffff;


$ts-outline-on-primary-border-hover: rgba(79, 66, 150, 0.2);
$ts-outline-on-secondary-border-hover: rgba(79, 66, 150, 0.4);
$ts-card-style-light: 0px 94px 147px rgba(198, 198, 197, 0.0647102), 0px 47.0459px 73.5718px rgba(198, 198, 197, 0.094071), 0px 28.3382px 44.3162px rgba(198, 198, 197, 0.115858), 0px 18.1606px 28.4001px rgba(198, 198, 197, 0.135), 0px 11.7703px 18.4067px rgba(198, 198, 197, 0.154142), 0px 7.40924px 11.5868px rgba(198, 198, 197, 0.175929), 0px 4.25707px 6.65734px rgba(198, 198, 197, 0.20529), 0px 1.87365px 2.93007px rgba(198, 198, 197, 0.27);


/* TALENT SOLUTIONS color-tokens */
$ts-background-primary: #ffffff;
$ts-background-on-primary-1: #000000;
$ts-background-on-primary-2: #67696f;
$ts-background-secondary: #000000;
$ts-background-on-secondary-1: #ffffff;
$ts-background-on-secondary-2: #939498;
$ts-container-on-background-primary: #ffffff;
$ts-container-on-container-primary-1: #000000;
$ts-container-on-container-primary-2: #67696f;
$ts-container-on-background-secondary: #282a32;
$ts-container-on-container-secondary-1: #ffffff;
$ts-container-on-container-secondary-2: #939498;
$ts-action-primary: #000000;
$ts-action-on-action-primary: #ffffff;
$ts-action-outline-primary: #939498;
$ts-action-on-outline-primary: #000000;
$ts-action-disabled-primary: #efefef;
$ts-action-on-disabled-primary: #939498;
$ts-action-secondary: #ffffff;
$ts-action-on-action-secondary: #000000;
$ts-action-outline-secondary: #67696f;
$ts-action-on-outline-secondary: #ffffff;
$ts-action-disabled-secondary: #282a32;
$ts-action-on-disabled-secondary: #67696f;
$ts-action-small-primary: #efefef;
$ts-action-on-small-primary: #000000;
$ts-action-small-secondary: #282a32;
$ts-action-on-small-secondary: #ffffff;
$ts-accent-1-primary: #5c4bb9;
$ts-accent-1-shade: #4f4296;
$ts-accent-1-small: #9e94fa;
$ts-accent-1-on-accent-1-primary: #ffffff;
$ts-accent-1-on-accent-1-small: #000000;
$ts-accent-2-shade: #5c7d70;
$ts-accent-2-small: #70bf5c;
$ts-accent-2-primary: #a6eb8c;
$ts-accent-2-on-accent-2-primary: #000000;
$ts-accent-2-on-accent-2-shade: #ffffff;
$ts-accent-3-primary: #56bded;
$ts-accent-3-shade: #386097;
$ts-accent-3-small: #3893cd;
$ts-accent-3-on-accent-3-primary: #000000;
$ts-accent-3-on-accent-3-small: #ffffff;
$ts-alert-success: #70bf5c;
$ts-alert-success-outline: #a6eb8c;
$ts-alert-alert: #e0690f;
$ts-alert-on-alert: #ffffff;
$ts-alert-error: #cc334d;
$ts-alert-error-outline: #fc5c7d;
$ts-alert-on-error: #ffffff;
$ts-alert-on-success: #ffffff;

