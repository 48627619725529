.calloutbar-section.footer.dual.global-calloutbar{
    padding: 5rem 0 0 2.5rem;
    .card-wrapper{
        .card{
            width: 100%;
            .sidebar .subTitle {
                font-size:1rem;
            }
            &:first-child{
                margin: 0;
                padding:2.75rem 1.75rem;
            }
        }


    }
}