@import './../../../scss/mixins';
@import './../../../scss/colors';

.article-block {
  &.biography {
    padding: 2.5rem 0;
    @include respond-between(md, xl) {
      padding: 2.5rem 5rem 4.5rem;
    }
    .breadcrumb {
      margin-bottom: 2rem;
    }
    .article-header-container {
      border-bottom: 1px solid $ts-background-on-primary-2;
      padding-bottom: 2rem;
      @include respond-below(md) {
        padding: 2rem 1.25rem 2rem 1.25rem;
      }
      .article-header {
        .title {
          line-height: 2.375rem !important;
          @include headline(4);
          margin-bottom: 0.5rem;
          color: $ts-background-on-primary-1;
        }
        .sub-title {
          @include headline(small);
          margin: 0.5rem 0 1.25rem;
          color: $ts-background-on-primary-2;
          @include respond-above(md) {
            margin: 0.5rem 0 0.75rem;
          }
        }
      }
      .social-follow {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        @include respond-above(sm) {
          flex-direction: row;
        }
        .social-item {
          @include label(medium);
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          line-height: 1;
          font-weight: normal;
          color: $ts-action-small-secondary;
          text-decoration: none !important;
          letter-spacing: normal;
          &:hover {
            color: $color-darkgray-75;
          }
          .icon {
            margin-right: 0.5rem;
          }
        }
      }
    }
    .single-bio {
      color: $ts-background-on-primary-1;
      margin-right: 4rem;
      @include tablet {
        margin-right: 0;
      }
      @include respond-below(md) {
        margin: 2.5rem 1.25rem 2.5rem 1.25rem;
      }
      .rich-text {
        p:first-child {
          margin-top: 0;
        }
      }
    }
    .sidebar__bio {
      .image-wrapper {
        @include respond-below(md) {
          padding: 2.5rem 1.25rem 1.25rem 1.25rem;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .title {
        @include headline(medium);
        margin: 1.5rem 0 1rem;
      }
      .item {
        display: block;
        margin: 1rem 0;
      }
    }
  }
  &.insights,
  &.webinar,
  &.podcast,
  &.report,
  &.video {
    padding: 2rem 0 5rem;
    @include respond-between(md, xl) {
      padding: 1.625rem 1rem 1.625rem 1rem;
    }
    .action-bar.related-content {
      margin: 2rem 0 0 0;
    }
    .article-sidebar {
      &.speakers {
        .sidebar-title {
          margin-bottom: 0.75rem;
          @include headline(4);
        }
        .card-list {
          margin: 0.25rem 0;
        }
      }
    }
    &__listing {
      .sidebar {
        margin: 2.5rem 0 0;
        @include respond-above(md) {
          margin: 0;
        }
      }
      .podcast-providers {
        margin: 2.5rem 0 0;
      }
    }
  }
  &.event {
    .sub-title {
      color: $ts-background-on-primary-2;
    }
    &__listing {
      .card__featured {
        flex-basis: 100%;
        .img-wrapper {
          flex-basis: 50%;
          aspect-ratio: 1;
        }
      }
      .pagination-wrapper {
        margin: 4rem 0;
      }
    }
  }
}
.tag.article.article-podcast {
  background-color: #a6eb8c;
}
.insight_details_block_richtext {
  div {
    h2 {
      font-size: 2rem;
      line-height: 2.375rem;
      letter-spacing: -0.5px;

      @include mobile {
        font-size: 1.5rem;
        line-height: 1.875rem;
        letter-spacing: -0.5px;
      }
    }
    h3 {
      font-size: 1.75rem;
      line-height: 2.125rem;
      letter-spacing: -0.5px;

      @include mobile {
        font-size: 1.25rem;
        line-height: 1.625rem;
        letter-spacing: -0.5px;
      }
    }
    p {
      font-size: 1rem;
      line-height: 1.375rem;
      letter-spacing: 0px;
    }
  }
}
