@import '../../../../scss/colors';
@import '../../../../scss/mixins';

@mixin pre-line($size) {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    top: 50%;
    background: $ts-action-outline-primary;
    @if $size == 'mobile' {
      width: 20px;
      left: -21px;
    } @else if $size == 'large' {
      width: 12px;
      left: -13px;
    }
  }
}

.dt-breadcrumb {
  flex-basis: 100%;

  @include respond-above(md) {
    flex-basis: auto;
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 12px;
    width: fit-content;

    @include respond-above(xs) {
      display: flex;
    }
  }

  &__item {
    @include label(small);
    width: fit-content;
    line-height: 0.75rem;
    padding: 7px 12px;
    color: $ts-action-on-small-primary;
    background: $ts-action-small-primary;
    border: 1px solid $ts-action-outline-primary;
    border-radius: 40px;
    text-align: center;
    cursor: default;

    @include respond-below(md) {
      &:nth-child(odd) {
        position: relative;
        z-index: 1;
      }
      &:nth-child(even) {
        @include pre-line(mobile);
      }
    }

    @include respond-above(xs) {
      &:not(:first-of-type) {
        @include pre-line(large);
      }
    }

    &:first-of-type {
      color: $ts-action-on-outline-primary;
      background: $ts-background-primary;
    }

    &:last-of-type {
      color: $ts-accent-1-on-accent-1-primary;
      background: $ts-accent-1-primary;
    }
  }
}
