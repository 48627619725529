@import '../../../scss/fonts';
@import '../../../scss/mixins';
@import '../../../scss/colors';
@import '../../../scss/variables';
.two-column-container:has(.InsightsDetailBlock) {
  padding-top: 0;
}
.two-column-container {
  @include respond-above(md){
  display: flex;

  justify-content: center;

  padding: 2.5rem 5rem 5rem 5rem;
  .card-block.insights{
    .card.card__listing{
      .cttagcontainer{
        .datect{
          position: unset;
        }
      }
    }
  }
}
  @include respond-below(md) {
    padding: 2rem 1.25rem 3.75rem 1.25rem;
  }

  .left-container {
    margin-right: 6.5rem;

    @include mobile {
      margin-bottom: 3rem;
    }
  }
 
    .card-block.insights{
      .card.card__listing{
        .cttagcontainer{
          @include respond-below(md){
          margin: 0;
          }
        }
      }
      .columns.cards {
        @include respond-above(md) {
          margin-bottom: 48px;
        }
        @include respond-below(md) {
          margin-bottom: 40px;
        }
    }
  }
}
