@mixin page-header {
  input {
    @include inter-font(1.75rem, 1.75rem);
    outline: none;
    border: 0;
    border-bottom: 1px solid $gray-50;
    width: 100%;
    background-color: inherit;
    color: var(--#{$prefix}txt-color);
  }
  .header {
    &--main {
      padding: 0;
      .search-icon {
        > svg:hover {
          color: $violet-75;
        }
        > svg {
          color: var(--#{$prefix}txt-color);
        }
        &-lauto {
          margin-left: auto;
          @include transition(all 1s);
        }
        &-rauto {
          margin-right: auto;
          @include transition(all 3s);
        }
      }
      &-container {
        &-searchmode {
          display: none !important;
        }
      }
      &--nav {
        .nav-item {
          padding: 2rem 2rem 2rem 0;
          display: flex;
          align-items: center;
          @include transition(padding-right 0.3s linear);
          span {
            @include inter-font(3.22rem, 2.25rem);
          }
          a.menu-grey-link {
            @include inter-font(1.25rem, 1.5rem);
            color: $gray-75;
            text-decoration: none;
          }
          &-large-arrow {
            margin-left: auto;
          }
          &:hover {
            color: $violet-75;
            padding-right: 0;
          }
          @include media-breakpoint-up(lg) {
            margin-right: 2.5rem;
            width: auto;
            padding: 0;
            align-self: center;
          }
          .nav-icon-link {
            margin-left: auto;
          }
        }
      }
      &-search {
        @extend .header--main--nav;
        .nav-item {
          padding: 0 0 1.25rem 0;
          margin-left: auto;
        }
        .nav-item:first-child {
          padding: 1.25rem 0 1.25rem 0;
          margin-left: 0;
        }
      }
      &-recent {
        @extend .header--main--nav;
        ul > li {
          background-color: transparent;
          border: none;
          padding: 0.5rem;
        }
        ul > li:first-child {
          padding-left: 0;
        }
        ul > li:last-child {
          padding-right: 0;
        }
      }
    }
  }
}

.header {
  &.ts__light {
    @include bg-mixin('light') {
      background-color: var(--#{$prefix}bgcolor);
      color: var(--#{$prefix}txt-color);
    }

    @include page-header;
  }
  &.ts__dark {
    @include bg-mixin('dark') {
      background-color: var(--#{$prefix}bgcolor);
      color: var(--#{$prefix}txt-color);
    }
    @include page-header;
  }
}
