@import './../../../scss/colors';
@import './../../../scss/mixins';

.popular_results_card {
  position: relative;
  z-index: 19;
  max-width: 55.313rem;
  min-height: 8.125rem;
  padding: 3.75rem;
  background: $ts-color-white;
  border: 0.0625rem solid $ts-color-gray-25;
  border-radius: 0;
  border-width: 0.0625rem 0 0;
  &:last-child {
    border-width: 0.0625rem 0;
  }
  @include respond-below(md) {
    padding: 1.125rem 0.75rem;
  }

  @include respond-above(md) {
    &:hover {
      z-index: 20;
      border-radius: 0.75rem;
      box-shadow: 0 28.3382px 44.3162px rgba(198, 198, 197, 0.115858),
        0 18.1606px 28.4001px rgba(198, 198, 197, 0.135),
        0 11.7703px 18.4067px rgba(198, 198, 197, 0.154142),
        0 7.40924px 11.5868px rgba(198, 198, 197, 0.175929),
        0px 4.25707px 6.65734px rgba(198, 198, 197, 0.20529),
        0 1.87365px 2.93007px rgba(198, 198, 197, 0.27);
    }
  }

  &.solution {
    border-radius: 0;
    box-shadow: none;
    @include respond-above(md) {
      &:hover {
        z-index: 20;
        border-radius: 12px;
        box-shadow: 0 28.3382px 44.3162px rgba(198, 198, 197, 0.115858),
          0 18.1606px 28.4001px rgba(198, 198, 197, 0.135),
          0 11.7703px 18.4067px rgba(198, 198, 197, 0.154142),
          0 7.40924px 11.5868px rgba(198, 198, 197, 0.175929),
          0 4.25707px 6.65734px rgba(198, 198, 197, 0.20529),
          0 1.87365px 2.93007px rgba(198, 198, 197, 0.27);
      }
    }
    .card-body {
      padding: 0;
    }
  }
}

.popular_results_card {
  .popular-card-body{
    padding: 0;
  }
  .popular-col-lg-12{
    padding: 0 15px;
  }
  .card-details {
    display: flex;
    align-items: center;
    .tags {
      @include label(small);
      display: inline-flex;
      align-items: center;
      margin-right: auto;
      color: $ts-background-on-primary-1;
      .bullet {
        margin: 0 0.5rem;
      }
      &__2 {
        .tag {
          margin-right: 0.25rem;
        }
        .bullet {
          margin: 0 0.25rem 0 0;
        }
      }
    }
    .date {
      @include label(small);
      margin-left: 0.75rem;
      color: $ts-background-on-primary-2;
    }
    .content-type {
      text-transform: capitalize;
    }
  }
  .card-body {
    .pre_title {
      @include label(medium);
      margin: 1rem 0 0;
      color: $ts-background-on-primary-2;
    }
    .title {
      @include headline(5);
      margin: 1rem 0 1.25rem;
      letter-spacing: -0.031rem;
    }

    p {
      color: $ts-background-on-primary-2;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .job_information {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    justify-content: flex-start;

    .location {
      font-size: 1.25rem;
      display: flex;
      gap: 0.625rem;
      align-items: center;
    }
    .jobtype {
      font-size: 1.25rem;
      display: flex;
      gap: 0.625rem;
      align-items: center;
    }
  }
}

.popular_results_card {
  .button.tag {
    padding: 0.25rem 0.375rem;
    border-radius: 0.25rem;
    .icon {
      margin-left: 0;
      margin-right: 0.25rem;
    }
  }

  .button.tag.outline {
    background-color: transparent;
  }
}

.popular_results_card.search_results_card {
  .descBullets {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    margin-top: 1.25rem;
    margin-bottom: 0;
    padding-left: 1.25rem;
    max-width: 90%;

    li {
      margin-right: 2em;
      color: $ts-container-on-container-primary-2;
      &::marker {
        color: $ts-color-black;
      }

      @include respond-below(md) {
        font-size: 0.75rem;
      }
    }

    @include respond-below(md) {
      margin-top: 1.25rem;
    }

    &.searches {
      gap: 1rem;
      max-width: 100%;
      padding-left: 0;
      list-style: none;
      li {
        margin-right: 0;
        border: 0.025rem solid $ts-color-gray-50;
        border-radius: 1.25rem;
        font-size: 1rem;
        border-radius: 2.5rem;
        padding: 0.375rem 1rem;
        color: $ts-color-black;
      }
    }
  }

  .searchBullets {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    margin-top: 1.25rem;
    margin-bottom: 0;
    padding-left: 1.25rem;
    max-width: 90%;

    list-style: none;
    padding-left: 0;
    max-width: 100%;
    gap: 1rem;

    li {
      margin-right: 0;
      border: none;
      border-radius: none;
      font-size: 1rem;
      border-radius: 2.5rem;
      padding: 0;
    }
  }

  .searchBulletBtn {
    background-color: transparent;
    color: $ts-color-black;
    outline: 0;
    border: 0;
    margin-right: 0;
    border: 0.0625rem solid #939498;
    border-radius: 1.25rem;
    font-size: 1rem;
    border-radius: 2.5rem;
    padding: 0.375rem 1rem;

    &:hover {
      border-color: $ts-color-violet-100;
      background-color: $ts-color-violet-100;
      color: $ts-color-white;
    }
  }
}
