@import '../../../scss/colors';
@import '../../../scss/mixins';

$form-transition: 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);

.form-block {
  position: relative;
  label {
    @include label(medium);
    margin-bottom: 0.5rem;
  }
  &.select-active {
    select {
      & + label {
        display: none;
      }
    }
  }
  &.error {
    input,
    select {
      background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjUxMiIgd2lkdGg9IjUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsPSIjOUQzMjNEIiBkPSJNNDM3LjUgMzg2LjZMMzA2LjkgMjU2bDEzMC42LTEzMC42YzE0LjEtMTQuMSAxNC4xLTM2LjggMC01MC45LTE0LjEtMTQuMS0zNi44LTE0LjEtNTAuOSAwTDI1NiAyMDUuMSAxMjUuNCA3NC41Yy0xNC4xLTE0LjEtMzYuOC0xNC4xLTUwLjkgMC0xNC4xIDE0LjEtMTQuMSAzNi44IDAgNTAuOUwyMDUuMSAyNTYgNzQuNSAzODYuNmMtMTQuMSAxNC4xLTE0LjEgMzYuOCAwIDUwLjkgMTQuMSAxNC4xIDM2LjggMTQuMSA1MC45IDBMMjU2IDMwNi45bDEzMC42IDEzMC42YzE0LjEgMTQuMSAzNi44IDE0LjEgNTAuOSAwIDE0LTE0LjEgMTQtMzYuOSAwLTUwLjl6Ii8+PC9zdmc+);
      background-size: 1.25rem 1.25rem;
      background-repeat: no-repeat;
      background-position: right 1.25rem center;
      box-shadow: inset 0px 0px 0px 1px $ts-alert-error-outline;
      border-color: $ts-alert-error-outline;
      &:focus {
        box-shadow: inset 0px 0px 0px 1px $ts-alert-error-outline;
        border-color: $ts-alert-error-outline;
      }
    }
  }
  &.success {
    input,
    select {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjQ3IDBDMTEuODIgMi41NjUgOC43NDQgNS41MjggNi4wMjIgOC4xNjNMMS4zOTYgNC4zNjYgMCA1Ljk1bDUuMzg0IDQuNDIzLjc1Ny42MjYuNzA3LS42ODNjMi44Ny0yLjc4IDYuMjgtNi4wNjMgOS4xNTItOC44NDZMMTQuNDcgMHoiIGZpbGw9IiM0MzgwODAiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-size: 1.25rem 1.25rem;
      background-position: right 1.25rem center;
      box-shadow: inset 0px 0px 0px 1px $ts-alert-success-outline;
      border-color: $ts-alert-success-outline;
      &:focus {
        box-shadow: inset 0px 0px 0px 1px $ts-alert-success-outline;
        border-color: $ts-alert-success-outline;
      }
    }
  }
  &.search {
    input,
    select {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS45OSIgaGVpZ2h0PSIyMS44OSIgdmlld0JveD0iMCAwIDIxLjk5IDIxLjg5Ij4KICA8cGF0aCBpZD0iU2hhcGUiIGQ9Ik0xOC43OTIsMTkuNzkzbC02LjA0LTYuMDRhNy42MzksNy42MzksMCwwLDEtNC45NywxLjgxMyw3LjgyLDcuODIsMCwxLDEsNS45NzEtMi44MTNsNi4wNCw2LjA0YS43MDguNzA4LDAsMCwxLDAsMSwuNzA4LjcwOCwwLDAsMS0xLDBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjk0NSAwLjk0NSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2FiYWJhYiIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjEuODkiLz4KPC9zdmc+Cg==);
      background-repeat: no-repeat;
      background-position: right 1.25rem center;
    }
  }
  &.input-icon {
    input {
      padding-left: 3rem;
      background-repeat: no-repeat;
      background-position: left 1.5rem center;
      @include tablet {
        padding-left: 2.25rem;
        background-position: left 1rem center;
      }
    }
  }
  input {
    display: block;
    width: 100%;
    height: 3.375rem;
    line-height: 3.375rem;
    border: 1px solid $ts-action-outline-primary;
    border-radius: 0.5rem;
    background-color: $ts-background-primary;
    color: $ts-container-on-container-primary-1;
    font-size: 1rem;
    padding: 0 1.25rem;
    outline-color: transparent;
    transition: $form-transition;
    box-shadow: none;
    outline: none;
    @include placeholder-text($ts-container-on-container-primary-2);
    &:focus {
      border: 0.125rem solid $ts-accent-1-primary;
      box-shadow: inset 0px 0px 0px 1px $ts-accent-1-primary;
    }
  }
  textarea {
    display: block;
    width: 100%;
    border: 1px solid $ts-action-outline-primary;
    border-radius: 0.5rem;
    background-color: $ts-background-primary;
    color: $ts-container-on-container-primary-1;
    font-size: 1rem;
    padding: 1.25rem 1.25rem 0;
    outline-color: transparent;
    transition: $form-transition;
    min-height: 10rem;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    @include placeholder-text($ts-container-on-container-primary-2);
    &:focus {
      border: 0.125rem solid $ts-accent-1-primary;
      box-shadow: inset 0px 0px 0px 1px $ts-accent-1-primary;
    }
  }
  .error-msg {
    font-size: 0.75rem;
    line-height: 1;
    color: $ts-alert-error;
  }
  .helper-text {
    padding: 1rem 0 0;
    font-size: 0.75rem;
    line-height: 1;
    color: $ts-container-on-container-primary-1;
  }
  select {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 0;
    box-shadow: none;
    height: 3.375rem;
    line-height: 1.375rem;
    border: 1px solid $ts-action-outline-primary;
    border-radius: 0.5rem;
    color: $ts-container-on-container-primary-1;
    font-size: 1rem;
    padding: 0 2rem 0 1.25rem;
    outline-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    background-color: $ts-background-primary;
    background-image: url('../../static/images/icon--form-chevron-down.svg');
    background-repeat: no-repeat;
    background-position: right 0.75rem top 50%, 0 0;
    background-size: 1.125rem auto, 100%;
    &::-ms-expand {
      display: none;
    }
    &:focus {
      border: 0.125rem solid $ts-accent-1-primary;
      box-shadow: inset 0px 0px 0px 1px $ts-accent-1-primary;
    }
    & + label {
      position: absolute;
      top: 1.75rem;
      transform: translateY(-50%);
      left: 1.25rem;
      right: 2.25rem;
      overflow: hidden;
      font-weight: 500;
      font-size: 1rem;
      color: $ts-container-on-container-primary-1;
    }
    &:focus {
      border: 0.125rem solid $ts-accent-1-primary;
      box-shadow: inset 0px 0px 0px 1px $ts-accent-1-primary;
    }
  }
}

.form-indicator {
  color: $ts-container-on-container-primary-1;
  padding-bottom: 0.3rem;
  font-size: 0.875rem;
}

.checkbox {
  input[type='checkbox'] {
    opacity: 0; // Hide
    display: initial;
    width: auto;
    height: auto;
    outline: auto;
    position: relative;
    z-index: 1;
    left: 6px;
    bottom: 3px;
  }
  label {
    &.contact-checkbox-label {
      line-height: 1.16rem;
      font-size: 0.75rem;
    }
    cursor: pointer;
    position: relative;
    display: inline-block;
    align-items: center;
    padding-left: 2.5rem;
    line-height: -0.5;
    &:before,
    &:after {
      position: absolute;
      content: '';
      display: inline-block;
    }
    &:before {
      height: 1.125rem;
      width: 1.125rem;
      background-color: $ts-background-primary;
      border: 1px solid $ts-action-outline-primary;
      border-radius: 0.25rem;
      left: 10px;
      top: 0.125rem;
    }
    &:after {
      height: 5px;
      width: 10px;
      border-left: 0.125rem solid $white;
      border-bottom: 0.125rem solid $white;
      transform: rotate(-45deg);
      left: 14px;
      top: 8px;
    }
  }
  // Hide by default
  input[type='checkbox'] + label::after {
    content: none;
  }

  // Show when checked
  input[type='checkbox']:checked + label::after {
    content: '';
  }

  input[type='checkbox']:checked + label::before {
    background-color: $ts-accent-1-primary;
  }

  // Focus state
  input[type='checkbox']:focus + label::before {
    outline: $ts-accent-1-primary auto 0.25rem;
  }
}

.radio {
  input[type='radio'] {
    opacity: 0; // Hide
    display: initial;
    width: auto;
    height: auto;
    outline: auto;
    position: relative;
    z-index: 1;
    left: 6px;
    bottom: 3px;
  }
  label {
    cursor: pointer;
    position: relative;
    left: -0.625rem; // Overlay Native Radio Input
    display: inline-flex;
    align-items: center;
    padding-left: 1.75rem;
    line-height: 1.5;
    &:before,
    &:after {
      position: absolute;
      content: '';
      display: inline-block;
    }
    &:before {
      height: 20px;
      width: 20px;
      background-color: $ts-background-primary;
      border: 1px solid $ts-action-outline-primary;
      left: 0px;
      top: 0.125rem;
      border-radius: 50%;
    }
    &:after {
      // content: none;
      height: 20px;
      width: 20px;
      background-color: $ts-background-primary;
      border: 1px solid $ts-action-outline-primary;
      left: 0px;
      top: 0.125rem;
      border-radius: 50%;
    }
  }
  // Hide by default
  input[type='radio'] + label::after {
    content: none;
  }

  // Show when checked
  input[type='radio']:checked + label::after {
    content: '';
    height: 16px;
    width: 16px;
    background-color: $ts-accent-1-primary;
    left: 0.125rem;
    top: 4px;
    border-radius: 50%;
  }

  input[type='radio']:checked + label::before {
    background-color: $ts-background-primary;
  }

  // Focus state
  input[type='radio']:focus + label::before {
    box-shadow: 0 0 0 1pt $ts-accent-1-primary;
  }
}
