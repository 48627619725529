@import '../../../scss/mixins';
@import '../../../scss/colors';

.action-bar {
  &.related-content {
    padding: 0.75rem 0 0;
    .title {
      @include headline(4);
      margin: 0 0 0.75rem 0;
    }
    .tag-wrapper {
      display: flex;
      flex-wrap: wrap;
      .tag {
        margin: 0 1rem 1rem 0;
      }
    }
    .tag-wrapper-banner {
      display: flex;
      flex-wrap: wrap;
      padding: 0.75rem 0 0 0;
      .tag {
        margin: 0 0.5rem 0.5rem 0;
      }
    }
  }
  &.podcast-providers {
    padding: 0.75rem 0 0.75rem;
    @include respond-above(sm) {
      margin-top: 2.5rem;
    }
    @include respond-below(sm) {
      margin: 2.5rem 0 2.5rem;
      padding: 0;
    }

    .title {
      @include headline(small);
      margin: 0 0 0.75rem 0;
    }
    .tag-wrapper {
      display: flex;
      flex-wrap: wrap;
      .tag {
        margin: 0 1rem 1rem 0;
      }
    }
  }
}
.banner-section.standard.banner.services{
  .action-bar{
    .tag-wrapper-banner{
      button{
        pointer-events: none;
      }
    }
  }
}
