@import '../../../scss/fonts';
@import '../../../scss/mixins';
@import '../../../scss/colors';
@import '../../../scss/variables';

.dp-down {
  .toggle-container {
    width: max-content;
  }
  .show {
    .toggle-container {
      width: 100%;
    }
  }
  .form-control {
    margin-top: 0px;
    margin-bottom: -4px;
    font-size: 0.75rem;
    padding-bottom: 0px;
    height: 2.5rem;
    @include mobile {
      margin-bottom: -2px;
    }
  }
  .form-control:focus {
    box-shadow: none;
    border-color: $mpg-color-violet-75;
  }
  .dropdown-menu {
    border-radius: 0px;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    margin-bottom: -17px !important;
    .dropdown-item {
      font-size: 0.75rem;
      color: $color-css-gray;
    }
    .dropdown-item:active {
      background-color: transparent;
    }
  }
  .item-label {
    cursor: pointer;
    width: auto;
    text-align: left;
    padding: 4px;
    font-size: 11px;
    font-weight: 700;
    font-size: 1rem;
    font-family: $font-heading;
    span {
      text-decoration: underline;
      color: $white !important;
      font-weight: 400;
      // text-transform: capitalize;
      cursor: pointer;
    }
  }

  // tiny scroll
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $color-antiflash-white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $color-cool-gray;
    border-radius: 0.625rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $color-cool-gray;
  }

  //floating label
  .floating-label {
    display: block;
    transform: translate(0, -2.75rem);
    font-size: 9px;
    padding-left: 13px;
    padding-top: 0.75rem;
    color: $color-lightgray;
    @include mobile {
      padding-left: 0.75rem;
      padding-top: 4px;
    }
  }

  @supports (not (-ms-ime-align: auto)) {
    .floating-label {
      // transform: translate(0.25rem, -1.5rem);
      transition: all 0.2s ease-out;
    }

    input:focus + .floating-label,
    input:not(:placeholder-shown) + .floating-label {
      //transform: translate(0, -2.75rem);
      font-size: 0.625rem;
    }
  }

  .input-gp {
    height: 35px;
    input {
      width: 100%;
    }
  }

  /* Remove IE default X */
  input::-ms-clear {
    display: none;
  }
}
.footer-rightside {
  .label-footer {
    color: $color-azure-blue !important;
    span {
      text-decoration: underline;
      color: $color-tele-gray !important;
      cursor: pointer;
      margin-left: 0.625rem;
    }
  }
}
