@import '../../../scss/colors';
@import '../../../scss/variables';
@import '../../../../scss/variables';
@import '../../../scss/mixins';
@import '../../../globals/typography/typography.scss';

// TS Theme Card Components Overrides
.card-block {
  &.solutions__callout {
    .callout {
      &.callout__solution {
        &.active {
          padding: 1.25rem;
        }
      }
    }
  }
  &.offerings {
    .card-wrapper {
      .card-body {
        .tags:nth-child(1) {
          margin-top: auto;
        }
        .cta__wrap__offering {
          @include respond-above(md) {
            margin-top: unset;
          }
        }
      }
    }
  }
}
.card {
  display: flex;
  flex-direction: column;
  border-radius: $ts-card-border-radius;
  color: inherit;
  text-decoration: none !important;
  letter-spacing: initial;
  overflow: hidden;
  transition: $btn-transition;
  border: 0;
  box-shadow: 0px 1.874px 2.93px 0px rgba(198, 198, 197, 0.27),
    0px 4.257px 6.657px 0px rgba(198, 198, 197, 0.21),
    0px 7.409px 11.587px 0px rgba(198, 198, 197, 0.18),
    0px 11.77px 18.407px 0px rgba(198, 198, 197, 0.15),
    0px 18.161px 28.4px 0px rgba(198, 198, 197, 0.14);
  &.primary {
    background-color: $ts-background-primary;
    color: $ts-background-on-primary-1;
    .title,
    .name {
      color: $ts-background-on-primary-1;
    }
    .sub-title {
      color: $ts-background-on-primary-2;
      @include mobile
      {
        display: none;
      }
    }
  }
  &.secondary {
    background-color: $ts-background-secondary;
    color: $ts-background-on-secondary-1 !important;
    .title,
    .name,
    .sub-title {
      color: $ts-background-on-secondary-1;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.1px;
    }
  }
  &.violet {
    background-color: $ts-accent-1-primary;
    color: $ts-background-on-secondary-1 !important;
    .title,
    .name,
    .sub-title {
      color: $ts-accent-1-on-accent-1-primary;
    }
    .title-quote {
      color: $ts-background-on-secondary-1 !important;
    }
  }
  &.green {
    background-color: $ts-accent-2-primary;
    color: $ts-accent-2-on-accent-2-primary;
    .title,
    .name,
    .sub-title {
      color: $ts-accent-2-on-accent-2-primary;
    }
  }
  &.blue {
    background-color: $ts-accent-3-primary;
    color: $ts-accent-3-on-accent-3-primary;
    .title,
    .name,
    .sub-title {
      color: $ts-accent-3-on-accent-3-primary;
    }
  }
  &.benefit {
    text-align: center;
    border-radius: unset;
    box-shadow: unset;
  //   margin: 1rem 1rem 2rem;
    flex-basis: calc(100% - 2rem);
    @include respond-below(sm) {
      margin: 0 1rem;
    }
    @include respond-above(lg) {
      flex-basis: calc(33.33% - 2rem);
    }
    .card-body {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      height: 100%;

      .card-img {
        &.visible {
          padding-top: 5.5rem;
        }
      }

      .icon {
        max-height: 3.5rem;
        max-width: 3.5rem;
        margin: 0 auto 1rem auto;
        @include respond-above(lg) {
          max-height: 4rem;
          max-width: 4rem;
          margin: 0 auto 1.5rem auto;
        }
      }
      .image {
        height: 6.25rem;
        width: 6.25rem;
        max-width: 100%;
        margin: 0 auto 1rem auto;
        object-fit: cover;
        aspect-ratio: 1;
        border-radius: 100%;
      }
      .title {
        @include headline(small);
        text-align: center;
        margin: 0 0 1rem 0;
        @include respond-above(md) {
          @include headline(medium);
        }
      }
      .description {
        color: $ts-background-on-primary-2;
        margin-top: 0;
        margin-bottom: 1.5rem;
        @include respond-below(md) {
          margin-bottom: 1rem;
        }
      }
      .cta__wrap {
        margin-top: inherit;
      }
    }
  }
  &.bg-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 0.25rem;
    padding: 0;
    overflow: hidden;
    min-height: 16rem;
    word-break: break-word;
    &:hover {
      &:before {
        opacity: 0.85;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 100%;
      background: linear-gradient(#00000080, #00000080);
      opacity: 1;
      transition: $link-transition;
    }
    .card-body {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      height: 100%;
      padding: 2.75rem 1.5rem 1.5rem;
      h3 {
        font-family: $font-copy;
        font-style: normal;
        font-weight: 400;
        font-size: 1.75rem;
        line-height: 1.14;
        letter-spacing: -0.5px;
      }
      button {
        text-align: left;
        margin-top: auto;
      }
    }
  }
  &.footer-action {
    padding: 4rem 2.5rem;
    @include respond-below(lg) {
      padding: 2.75rem 1.75rem;
    }
    .card-body {
      display: flex;
      flex-direction: column;
      padding: 0;
      height: 100%;
      @include respond-above(lg) {
        flex-direction: row;
        align-items: center;
      }
      &.footer-gap {
        @include respond-below(lg) {
          gap: 2rem;
        }
        .title {
          width: 70%;
          font-size: 2.25rem;
          line-height: 2.625rem;
          @include respond-below(lg) {
            width: 100%;
            font-size: 24px;
            line-height: 30px; /* 125% */
            letter-spacing: -0.5px;
          }
        }
      }
      .cta__wrap {
        margin: auto 0 0 0;
        @include respond-above(lg) {
          width: 30%;
          margin: 0 0 0 auto;
        }
        button {
          display: flex;
          @include respond-above(lg) {
            margin: 0 0 0 auto;
          }
        }
      }
    }
  }
  &.podcast-embed {
    background: transparent;
    margin: 2.5rem 0;
    .cta__wrap {
      margin: 2rem 0 0.25rem;
    }
  }
  &.speaker {
    flex-direction: row;
    border-radius: 0;
    margin: 0.75rem 0;
    box-shadow: none;
    &:hover {
      .card-body {
        .job-title,
        .company {
          color: $ts-background-on-primary-1;
        }
      }
    }
    img {
      border-radius: $ts-card-border-radius;
      object-fit: cover;
      aspect-ratio: 1;
      height: 100%;
      width: 25%;
    }
    .headshot {
      aspect-ratio: 1;
      border-radius: 0.5rem;
      margin: 0 auto;
      object-fit: cover;
      min-width: 200px;
      min-height: 200px;
      @include respond-above(md) {
        min-width: 100px;
        min-height: 100px;
      }
    }
    .card-body {
      margin: 0 0 0 1.25rem;
      .name {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 1.5rem;
        font-family: Inter;
        letter-spacing: -0.005em;
        text-decoration: underline;
        margin: 0.75rem 0rem 0.5rem 0rem;
      }
      .job-title,
      .company {
        color: $ts-background-on-primary-2;
        line-height: 1.375rem;
        margin: 0rem;
        transition: $btn-transition;
      }
    }
    .hostright {
      min-width: 18.313rem;
      min-height: 6.25rem;
      margin: 0rem 0rem 0rem 1.25rem;
      padding: 0rem;
    }
    &.large {
      flex-direction: column;
      img {
        width: 100%;
      }
      .card-body {
        margin: 1.25rem 0 0 0;
      }
    }
  }
  &.team-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: unset;
    text-align: center;
    transition: $btn-transition;
    box-shadow: none;
    .card-body {
      background-color: $ts-background-primary;
      padding: 2.5rem;
      width: 100%;
      @include respond-above(md) {
        padding: 1.75rem 1.25rem;
      }
      .headshot {
        aspect-ratio: 1;
        border-radius: 100%;
        margin: 0 auto;
        object-fit: cover;
        max-width: 150px;
        max-height: 150px;
        @include respond-above(md) {
          max-width: 200px;
          max-height: 200px;
        }
      }
      .name-link {
        color: $ts-background-on-primary-1;
        text-transform: none !important;
        text-decoration: none !important;
        .name {
          @include headline(small-bold);
          margin: 2rem 0 0 0;
          color: inherit;
        }
        &:hover {
          color: $ts-background-on-primary-2;
        }
      }
      .job-title {
        color: $ts-background-on-primary-2;
        margin: 0.25rem 0 0.75rem 0;
        line-height: 1.375rem;
      }
      .social-follow {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0.25rem 0;
        .social-item {
          margin: 0 0.5rem;
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
  &.country-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: unset;
    text-align: center;
    transition: $btn-transition;
    box-shadow: none;
    width: 149px;
    .card-body {
      background-color: $ts-background-primary;
      padding: 1.25rem;
      .headshot {
        aspect-ratio: 1;
        border-radius: 100%;
        margin: 0 auto;
        object-fit: cover;
        width: 100px;
        height: 100px;
        justify-content: center;
      }
      .countryName {
        padding-top: 1.5rem;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 116.667% */
        letter-spacing: -0.5px;
        justify-content: center;
      }
      .name-link {
        color: $ts-background-on-primary-1;
        text-transform: none !important;
        text-decoration: none !important;
        .name {
          @include headline(small-bold);
          margin: 1.5rem 0 0 0;
          color: inherit;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 116.667% */
          letter-spacing: -0.5px;
        }
        &:hover {
          color: $ts-background-on-primary-2;
        }
      }
      .job-title {
        color: $ts-background-on-primary-2;
        margin: 0.25rem 0 0.75rem 0;
        @include respond-above(md) {
          margin: 0.75rem 0;
        }
      }
      .social-follow {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0.25rem 0;
        .social-item {
          margin: 0 0.5rem;
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
  &__carousel {
    &.event {
      background-color: $ts-background-primary;
      box-shadow: $ts-card-box-shadow-light;
      height: 100%;
      margin: 0;
      padding: 1.75rem 1.5rem 1.5rem;
      @include respond-above(lg) {
        padding: 2.25rem 2rem 2rem;
      }
      .card-footer-carousel {
        margin-top: 0.5rem;
      }
      &:hover,
      &:focus {
        z-index: 20;
        box-shadow: $ts-card-box-shadow-light-hover;
      }
      .card-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column-reverse;
        @include respond-above(lg) {
          flex-direction: row;
        }
        .card-details {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .tags {
            @include label(small);
            color: $ts-background-on-primary-1;
            display: inline-flex;
            align-items: center;
            margin-right: auto;
            .bullet {
              margin: 0 0.5rem;
            }
            &__2 {
              .tag {
                margin-right: 0.25rem;
              }
              .bullet {
                margin: 0 0.25rem 0 0;
              }
            }
          }
          .date {
            @include label(small);
            color: $ts-background-on-primary-2;
            margin-left: 0.75rem;
          }
        }
        .label {
          margin-bottom: 1rem;
        }
      }
      .card-body {
        .sub-title {
          @include label(small);
          color: $ts-background-on-primary-2;
          margin-top: 1.5rem;
          @include respond-above(md) {
            margin-top: 0rem;
          }
          @include respond-below(sm) {
            margin-top: 0rem;
          }
        }
        .title {
          @include headline(medium);
          margin: 0.5rem 0 0.75rem 0;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          @include respond-below(sm) {
            @include headline(small);
          }
        }
        .description {
          color: $ts-background-on-primary-2;
          margin: 0 0 2rem;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          &.mb-16 {
            margin-bottom: 1rem;
          }
        }
      }
      .card-footer {
        margin-top: auto;
      }
    }
  }
  &__listing {
    background-color: $ts-background-primary;
    box-shadow: $ts-card-box-shadow-light;
    margin: 0 0 1.25rem;
    padding: 3rem 2rem 2.5rem;
    &:hover {
      box-shadow: $ts-card-box-shadow-light-hover;
    }
    @include respond-above(lg) {
      padding: 3rem 2.5rem 2.5rem;
    }
    .card-header {
      display: flex;
      margin-bottom: auto;
      // flex-wrap: wrap;
      flex-wrap: nowrap;
      margin-bottom: 32px;
      justify-content: space-between;
      flex-direction: column-reverse;
      @include respond-above(lg) {
        flex-direction: row;
      }
      .card-details {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .tags {
          @include label(small);
          color: $ts-background-on-primary-1;
          display: inline-flex;
          align-items: center;
          margin-right: auto;
          .bullet {
            margin: 0 0.5rem;
          }
          &__2 {
            .tag {
              margin-right: 0.25rem;
            }
            .bullet {
              margin: 0 0.25rem 0 0;
            }
          }
        }
        .date {
          @include label(small);
          color: $ts-background-on-primary-2;
          margin-left: 0.75rem;
        }
      }
      .label {
        @include respond-below(lg) {
          margin-bottom: 1rem;
        }
      }
    }
    .card-body {
      .title {
        @include headline(5);
        margin: 2rem 0 0.75rem;
      }
      p {
        color: $ts-background-on-primary-2;
        margin: 0 0 2rem;
      }
    }
    .card-footer {
      margin-top: auto;
    }
  }
  &__insights {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    box-shadow: $ts-card-box-shadow-light;
    @include respond-above(sm) {
      flex-basis: calc(50% - 1rem);
      flex-direction: row;
    }
    &:hover,
    &:focus {
      z-index: 20;
      box-shadow: $ts-card-box-shadow-light-hover;
    }
    &.secondary {
      @include frostedGlass(extra-light);
      box-shadow: unset;
      .card-body {
        background-color: transparent;
        .card-details .content-type {
          text-transform: capitalize;
          color: $ts-background-on-secondary-1;
        }
        .card-details .date {
          color: $ts-background-on-secondary-2;
        }
      }
    }
    .img-wrapper {
      position: relative;
      flex-basis: 100%;
      .tag {
        position: absolute;
        top: 1rem;
        left: 1rem;
      }
      img {
        @include mobile {
          object-fit: fill;
        }
        object-fit: cover;
        aspect-ratio: 2 / 1;
        height: 100%;
        width: 100%;
        @include respond-above(md) {
          aspect-ratio: 1;
        }
        @include respond-above(lg) {
          aspect-ratio: 2 / 1;
        }
        @include respond-above(xl) {
          aspect-ratio: 1;
        }
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1.5rem 1.5rem 1.25rem 1.5rem;
      flex-basis: 100%;
      background-color: $ts-background-primary;
      @include respond-above(md) {
        padding: 2.5rem 2.5rem 2.25rem 2.5rem;
      }
      .card-details {
        display: flex;
        margin-bottom: auto;
        flex-wrap: wrap;
        .content-type {
          @include label(small);
          color: $ts-background-on-primary-1;
          display: inline-flex;
          align-items: center;
          margin-right: auto;
          text-transform: capitalize;
        }
        .tags {
          .bullet {
            margin: 0 0.5rem;
          }
          &__2 {
            .tag {
              margin-right: 0.25rem;
            }
            .bullet {
              margin: 0 0.25rem 0 0;
            }
          }
        }
        .date {
          @include label(small);
          color: $ts-background-on-primary-2;
          line-height: 2;
        }
      }
      .card-content {
        &.event__listing__card__content {
          margin-top: 3rem;
          @include mobile {
            margin-top: 1.5rem;
          }
        }
        margin-top: 1.25rem;
        @include respond-above(sm) {
          margin-top: 2rem;
        }
        .sub-title {
          @include label(small);
          color: $ts-background-on-primary-2;
        }
        .title {
          @include headline(small);
          @include respond-above(md) {
            @include headline(5);
          }
          @include desktop {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          @include respond-below(md) {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.1px;
          }
        }
        p {
          color: $ts-background-on-primary-2;
          margin: 0.75rem 0;
        }
      }
      .cta__wrap {
        &.event__listing__cta {
          margin-top: 1.25rem;
          @include mobile {
            margin-top: 1rem;
          }
        }
        margin-top: auto;
      }
    }
    &.featured {
      .card-details {
        margin: 0 0 1.5rem 0;
      }
      .card-content {
        margin: 0 0 auto;
        .title {
          @include headline(medium);
        }
      }
      .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        .related-solutions {
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
        }
      }
    }
  }
  .primary &__full {
    display: flex;
    flex-basis: auto;
    box-shadow: $ts-card-box-shadow-light !important;
    transition: $btn-transition;
    cursor: pointer;
    &:hover,
    &:focus {
      z-index: 20;
      box-shadow: $ts-card-box-shadow-light-hover !important;
    }
    .img-wrapper {
      position: relative;
      .tag {
        position: absolute;
        top: 1rem;
        left: 1rem;
      }
      img {
        object-fit: cover;
        aspect-ratio: auto;
        height: 100%;
        width: 100%;
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2rem 1.5rem 1.5rem;
      flex-grow: 1;
      background-color: $ts-background-primary;
      @include respond-above(lg) {
        padding: 3rem 2.5rem;
      }
      .card-details {
        display: flex;
        flex-wrap: wrap;
        .content-type {
          @include label(small);
          color: $ts-background-on-primary-1;
          display: inline-flex;
          align-items: center;
          margin-right: auto;
          text-transform: capitalize;
        }
        .tags {
          .bullet {
            margin: 0 0.5rem;
          }
          &__2 {
            .tag {
              margin-right: 0.25rem;
            }
            .bullet {
              margin: 0 0.25rem 0 0;
            }
          }
        }
        .date {
          @include label(small);
          color: $ts-background-on-primary-2;
          line-height: 2;
        }
      }
      .card-content {
        margin-top: 1.5rem;
        .sub-title {
          @include label(small);
          color: $ts-background-on-primary-2;
        }
        .sub-title-carousel {
          @include label(small);
          color: $ts-background-on-primary-2;
          padding-bottom: 0.75rem;
        }
        .title {
          @include headline(small);
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 0.75rem;
        }
        p {
          color: $ts-background-on-primary-2;
          margin: 0.75rem 0;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          // transition: $btn-transition;
        }
      }
      .related-solutions {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75rem;
        margin: auto 0 0 0;
      }
    }
    &.slide {
      // box-shadow: unset;
      .card-body {
        background-color: transparent;
      }
    }
    &.secondary {
      .card-body {
        .card-details {
          .content-type {
            text-transform: capitalize;
            color: $ts-background-on-secondary-1;
          }
          .date {
            color: $ts-background-on-secondary-2;
          }
        }
        .card-content {
          .sub-title,
          p {
            color: $ts-background-on-secondary-2;
          }
        }
      }
    }
  }
  &__event {
    flex-basis: 100%;
    margin: 0 1rem;
    &.event__listing {
      &:last-child {
        margin: 2.5rem 0 0 0;
        padding: 0;
        @include mobile {
          margin: 3.75rem 0;
        }
      }
      margin: 5rem 0 0 0;
      @include mobile {
        margin: 3.75rem 1.25rem;
      }
    }
    .img-wrapper {
      @include respond-above(sm) {
        flex-basis: 50%;
      }
    }

    .related-solutions {
      display: flex;
      flex-wrap: wrap;
      margin: 2rem 0 0 0;
      gap: 0 0.5rem;
      &.event__listing__solution {
        margin-top: 3rem;
        @include mobile {
          margin-top: 2rem;
        }
      }
    }
  }
  &__featured {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    box-shadow: $ts-card-box-shadow-light;
    // margin: 0.5rem;
    @include respond-above(sm) {
      flex-basis: calc(50% - 1rem);
      flex-direction: row;
    }
    .img-wrapper {
      position: relative;
      flex-basis: 100%;
      .tag {
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: auto;
      }
      img {
        object-fit: cover;
        aspect-ratio: 2 / 1;
        height: 100%;
        width: 100%;
        @include respond-above(md) {
          aspect-ratio: 1;
        }
        @include respond-above(lg) {
          aspect-ratio: 2 / 1;
        }
        @include respond-above(xl) {
          aspect-ratio: 1;
        }
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 3rem 2.5rem 2.5rem 2.5rem;
      flex-basis: 100%;
      background-color: $ts-background-primary;
      @include respond-above(lg) {
        padding: 3rem 2.5rem 2.5rem 2.5rem;
      }
      .card-details {
        display: flex;
        margin-bottom: auto;
        flex-wrap: wrap;
        .content-type {
          @include label(small);
          color: $ts-background-on-primary-1;
          display: inline-flex;
          align-items: center;
          margin-right: auto;
        }
        .tags {
          .bullet {
            margin: 0 0.5rem;
          }
          &__2 {
            .tag {
              margin-right: 0.25rem;
            }
            .bullet {
              margin: 0 0.25rem 0 0;
            }
          }
        }
        .date {
          @include label(small);
          color: $ts-background-on-primary-2;
        }
      }
      .card-content {
        margin-top: 2rem;
        background: transparent;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 0;
        &.insight {
          margin-top: 2.5rem;
        }
        .sub-title {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          color: $ts-background-on-primary-2;
          margin-bottom: 0;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .title {
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 34px; /* 121.429% */
          letter-spacing: -0.5px;
          margin-bottom: 0;
          @include respond-below(md) {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 120% */
            letter-spacing: -0.1px;
          }
          @include respond-above(md) {
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px; /* 121.429% */
            letter-spacing: -0.5px;
          }
        }
        p {
          color: $ts-background-on-primary-2;
          margin: 0.75rem 0;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
        .clamp-2 {
          @include respond-above(md) {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
          }
          @include tablet {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          @include respond-below(md) {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
      .cta__wrap {
        margin-top: auto;
      }
    }
  }
  &__callout {
    box-shadow: $ts-card-box-shadow-light;
    // margin: 0.5rem;
    flex-basis: auto;
    @include respond-above(md) {
      flex-basis: calc(50% - 1rem);
      padding: 4rem 2.5rem;
      padding-bottom: 2.5rem;
    }
    @include respond-above(lg) {
      padding: 4rem 2.5rem;
      padding-bottom: 2.5rem;
    }
    @include respond-below(md) {
      padding: 2.75rem 1.75rem;
      padding-bottom: 1.75rem;
    }
    .card-body {
      background: transparent;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 0;
      height: 100%;

      .title {
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px; /* 118.75% */
        letter-spacing: -0.5px;
        margin: 0 0 4rem 0;
      }
      .cta__wrap {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        @include respond-above(xl) {
          flex-direction: row;
        }
        button {
          width: fit-content;
          &:first-child {
            margin: 0 0 1rem 0;
            @include respond-above(xl) {
              margin: 0 2rem 0 0;
            }
          }
          &:only-child {
            margin: 10px;
          }
        }
      }
    }
  }
  &__offering {
    display: flex;
    flex-basis: auto;
    box-shadow: $ts-card-box-shadow-light;
    transition: $btn-transition;
    cursor: pointer;
    &:hover,
    &:focus {
      z-index: 20;
      box-shadow: $ts-card-box-shadow-light-hover;
    }
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1.75rem;
      flex-grow: 1;
      background-color: $ts-background-primary;
      @include respond-above(lg) {
        padding: 3rem;
      }
      .card-content {
        margin: 0 0 0.75rem 0;
        .title {
          @include headline(small);
        }
        .description {
          color: $ts-background-on-primary-2;
          margin: 0.5rem 0 1rem;
        }
        .service-provider {
          @include label(small);
          color: $ts-background-on-primary-2;
          span {
            @include label(small);
            font-weight: 600;
          }
        }
      }

      .card-content-offering {
        margin: 0;
        .title {
          @include headline(small);
          font-size: 1.25rem;
          font-weight: 400;
          font-family: $font-inter;
          line-height: 1.5rem;
          margin-bottom: 0rem;
        }
        .description {
          color: $ts-background-on-primary-2;
          margin: 0.5rem 0 1rem 0;
          font-family: $font-arial-2;
        }
        .service-provider {
          @include label(small);
          color: $ts-background-on-primary-2;
          span {
            @include label(small);
            font-weight: 600;
          }
        }
      }
      .related-solutions {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        p {
          @include label(small);
          color: $ts-background-on-primary-2;
          width: 100%;
          margin: 0;
        }
      }
      .cta__wrap {
        display: flex;
        justify-content: flex-start;
        margin: 1.5rem 0 0;
      }
    }
  }
  &__outcome {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: $ts-background-primary;
    box-shadow: $ts-card-box-shadow-light;
    overflow: hidden;
    margin: 0;
    transition: $btn-transition;
    cursor: pointer;
    &:hover,
    &:focus {
      z-index: 20;
      box-shadow: $ts-card-box-shadow-light-hover;
    }
    @include respond-above(md) {
      flex-direction: row;
    }
    .card-image {
      flex-grow: 1;
      flex-basis: 33.33%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      &.img-h200 {
        img {
          @include respond-below(sm) {
            height: 200px;
          }
        }
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;
      flex-basis: 66.66%;
      padding: 1.75rem;
      @include respond-above(md) {
        padding: 2.25rem;
      }

      .tag {
        margin-bottom: 1.5rem;
      }
      .title {
        @include headline(medium);
        margin: 0 0 0.5rem 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @include respond-above(md) {
          @include headline(small);
        }
        @include respond-below(md) {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.1px;
        }
        &.outcomes {
          margin-bottom: 24px;
          text-overflow: ellipsis;
          max-width: 400px;
          @include respond-above(md) {
            max-width: 400px;
          }
          @include respond-below(md) {
            max-width: 350px;
            margin-bottom: 8px;
          }
          @include mobile {
            // max-width: 200px;
          }
        }
      }
      .description {
        color: $ts-container-on-container-primary-2;
        margin: 0 0 1.25rem 0;
        transition: $btn-transition;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        max-width: 400px;
        @include respond-above(md) {
          max-width: 400px;
        }
        @include respond-below(md) {
          max-width: 350px;
        }
        @include mobile {
          // max-width: 200px;
        }
      }
      .cta__wrap {
        margin-top: auto;
      }
      .customer-outcomes {
        .tag {
          margin: 0rem 0.5rem 0.5rem 0rem;
        }
      }
    }
  }
  &.sidebar {
    &.sidebar-links {
      padding: 2.75rem 1.75rem 1.75rem 1.75rem;
      @include mobile {
        padding: 2.75rem 1.75rem 1.75rem;
      }
      .card-body {
        padding: 0;
      }
    }
    padding: 64px 40px;
    @include mobile {
      padding: 2.5rem 1.75rem 1.75rem;
    }
    .card-body {
      padding: 44px 28px 28px;
    }

    .title {
      font-family: $font-inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 38px; /* 118.75% */
      letter-spacing: -0.5px;
      margin-bottom: 2rem;
      &.related {
        font-size: 24px;
        line-height: 30px;
        &.title__1 {
          margin-bottom: 1.5rem;
        }
        &.title__2 {
          margin-bottom: 2.5rem;
        }
        &.title__multiple {
          margin-bottom: 1.75rem;
        }
      }
    }
    &__solutions {
      .title {
        margin: 0 0 1.5rem 0;
      }
      .cta__wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .button {
          margin: 0 0 1.75rem 0;
          @include respond-above(md) {
            margin: 0 0 2rem 0;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    &__relative {
      width: 234px;
      height: auto;
    }
    &__subscribe {
      .title {
        margin: 0;
        @include respond-below(lg) {
          font-family: $font-inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 125% */
          letter-spacing: -0.5px;
        }
      }
      .sub-title {
        margin: 0;
      }
      .hs-form-wrapper {
        form {
          .hs-form-field {
            &.hs-email {
              .input {
                margin-right: 0;
              }
            }
          }
        }
      }
      &.secondary {
        .hs-form-wrapper {
          form {
            .hs-submit {
              background: $ts-action-secondary;
              .actions {
                &:after {
                  content: '';
                  @include signPost(black);
                }
                input[type='submit'] {
                  color: $ts-action-on-action-secondary;
                }
              }
            }
          }
        }
      }
    }
  }
  &.solution {
    cursor: pointer;
    background-color: $ts-background-primary;
    box-shadow: $ts-card-style-light;
    transition: $btn-transition;
    .card-header {
      display: flex;
      align-items: center;
      // padding-right: 1.25rem;
      padding: 0;
      .icon {
        max-height: 2rem;
        max-width: 2rem;
        //margin: 0 0 5.5rem 0;
        &.service {
          @include mobile {
            margin: 0 3.25rem 0 0;
          }
        }
        @include respond-above(md) {
          max-height: 4rem;
          max-width: 4rem;
        }
        @include mobile {
          margin: 0 1.25rem 0 0;
        }
      }
      .title {
        display: block;
        @include headline(small);
        width: 100%;
        margin: 0;
        @include respond-above(md) {
          @include headline(medium);
        }
        &:after {
          content: '';
          position: absolute;
          background-image: url('../../../static/images/icon--expand.svg');
          background-size: 1rem;
          background-repeat: no-repeat;
          width: 1rem;
          height: 1rem;
          right: 1rem; // Don't change
          // top: 50%;
        }
      }
      &.solution-card-header {
        .icon {
          margin-bottom: 3.25rem;
        }
      }
    }

    .card-body {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      height: 100%;
      overflow: hidden;
      border-radius: $ts-card-border-radius;
      background: $ts-background-primary;
      @include mobile {
        padding: 1.25rem 2.25rem 1.25rem 1.25rem; //do not change
      }
      .card-header h2.title{
        text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
      }
      .card-content {
        margin-top: auto;
        display: none;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        @include respond-above(md) {
          display: flex;
        }
        .title {
          @include headline(small);
          margin-bottom: 1rem;
          @include respond-above(sm) {
            margin-bottom: 0.5rem;
          }
        }
        p {
          color: $ts-container-on-container-primary-2;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          &.description {
            margin-bottom: 16px;
            margin-top:0;
          }
          &.solution-para {
            margin: 0 0 1rem 0;
            @include mobile {
              margin: 0 0 1.25rem 0;
            }
          }
          @include mobile {
            overflow: visible;
            display: block;
          }
        }
        button {
          opacity: 0;
        }
      }
    }
  }
  &.service {
    cursor: pointer;
    background-color: $ts-background-primary;
    box-shadow: $ts-card-box-shadow-light;
    flex-basis: calc(33.33% - 0.665rem);
    transition: $btn-transition;
    z-index: 9;
    &:focus,
    &:hover {
      box-shadow: $ts-card-box-shadow-light-hover;
    }
    .card-header {
      display: flex;
      align-items: center;
      position: relative;

      &.no-padding {
        padding: 0;
      }

      .icon {
        max-height: 2rem;
        max-width: 2rem;
        margin: 0 1rem 0 0;
        @include respond-above(md) {
          max-height: 4rem;
          max-width: 4rem;
          margin: 0 0 3.75rem 0;
        }
      }
      .title {
        display: block;
        @include headline(small);
        width: 100%;
        margin: 0;
        @include respond-above(md) {
          display: none;
        }
        &:after {
          content: '';
          position: absolute;
          background-image: url('../../../static/images/icon--expand.svg');
          background-size: 1rem;
          background-repeat: no-repeat;
          width: 1rem;
          height: 1rem;
          right: 1rem;
        }
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;
      padding: 1.25rem;
      height: 100%;
      overflow: hidden;
      border-radius: $ts-card-border-radius;
      background: $ts-background-primary;
      @include respond-above(md) {
        padding: 2.5rem;
      }
      .card-content {
        margin-top: auto;
        display: none;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;
        padding: 2.5rem 0 1.25rem;
        @include respond-above(md) {
          display: flex;
          padding: 0;
        }
        .title {
          @include headline(small);
          margin-bottom: 0;
          @include respond-above(md) {
            @include headline(medium);
          }
        }
        .service-provider {
          @include label(small);
          color: $mpg-color-gray-75;
          margin-top: 0.5rem;
          strong {
            @include label(small);
            font-weight: 700;
          }
        }
        p {
          color: $mpg-color-gray-75;
          margin: 1rem 0;
        }
        button {
          margin-top: auto;
        }
      }
      .card-content-service {
        margin-top: auto;
        display: none;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;
        padding: 1.25rem 0 1.25rem;
        @include respond-above(md) {
          display: flex;
          // padding: 0;
        }
        .title {
          @include headline(small);
          margin-bottom: 0;
          @include respond-above(md) {
            @include headline(medium);
          }
        }
        .service-provider {
          @include label(small);
          color: $mpg-color-gray-75;
          margin-top: 0.5rem;
          strong {
            @include label(small);
            font-weight: 700;
          }
        }
        p {
          color: $mpg-color-gray-75;
          margin: 1rem 0;
          margin-top: 0.5rem;
        }
        button {
          margin-top: auto;
        }
      }
    }
    &.active {
      scale: 1;
      margin: 0;
      // margin: 0 0 1rem 0;
      .card-header {
        align-items: flex-start;
        @include respond-below(md) {
          margin-top: 1rem;
        }
        .icon {
          max-height: 4rem;
          max-width: 4rem;
          margin-bottom: 3.75rem;
          @include respond-below(xl) {
            margin-bottom: 1.25rem;
          }
        }
        .title {
          margin: 0;
          // visibility: hidden;
          visibility: visible;
          &:after {
            visibility: visible;
            background-image: url('../../../static/images/icon--collapse.svg');
          }
        }
      }
      .card-content {
        @include respond-below(md) {
          display: block;
        }
      }
      .card-body {
        .card-content-service {
          display: block;
          .title {
            display: none;
          }
        }
      }
    }
  }
  &.subnav {
    &:hover {
      text-decoration: none;
      .arrow-icon {
        transform: translateX(0.25rem);
      }
    }
    .card-body {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      height: 100%;
      .arrow-icon {
        @include arrow-Right(blue-50);
        width: 1.75rem;
        height: 1.75rem;
        background-size: 1.75rem 1.75rem;
        position: absolute;
        margin: 0;
        right: 1.5rem;
      }
      h3 {
        color: $black;
        font-family: $font-copy;
        font-style: normal;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 1.22;
        letter-spacing: -0.005em;
        margin: 0 1rem 2rem 0;
      }
      p {
        font-family: $font-copy;
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 1.66;
        color: $mpg-color-gray-75;
        letter-spacing: initial;
        margin: 0;
        margin-top: auto;
      }
    }
  }
  &.quote {
    height: 100%;
    margin: 0;
    box-shadow: $ts-card-box-shadow-light;
    &:hover {
      box-shadow: $ts-card-box-shadow-light-hover;
    }
    &.violet {
      background-color: #5c4bb9;
    }
    .card-body {
      display: flex;
      flex-direction: column;
      padding: 2.75rem 1.75rem 1.75rem;
      flex-grow: 1;
      @include mobile {
        padding: 2.75rem 1.25rem 1.75rem;
      }
      &.primary {
        color: $ts-background-on-primary-1 !important;
        background: transparent;
      }
      &.secondary {
        color: $ts-background-on-secondary-1 !important;
      }
      .title-quote {
        margin-bottom: auto;
        font-size: 1.5rem;
        line-height: 1.75rem;
        letter-spacing: -0.5px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        @include mobile {
          margin-bottom: auto;
          font-size: 1.25rem;
          line-height: 1.5rem;
          letter-spacing: -0.1px;
        }
      }
      .title {
        @include headline(small);
        margin-bottom: auto;
        @include respond-above(md) {
          @include headline(medium);
        }
      }
      .name {
        @include label(medium);
        margin: 4rem 0 0;
        @include respond-above(md) {
          @include headline(small);
        }
      }
    }
    &.primary {
      .name {
        color: $ts-background-on-primary-2;
        transition: $btn-transition;
      }
      &:hover {
        .name {
          color: $ts-background-on-primary-1;
        }
      }
    }
    &.secondary {
      .name {
        color: $ts-background-on-secondary-2;
      }
    }
  }
}
.card-body > div {
  width: 100%;
  display: flex;
}
.card__outcome .card-body .tag {
  margin-right: 1rem;
}
.cta__wrap.sidecard {
  flex-direction: column;
  &.related {
    display: flex;
    align-items: flex-start;
    gap: 28px;
    &.related-links {
      gap: 2rem;
      @include mobile {
        gap: 1.75rem;
      }
    }
  }
}
.cta__wrap.sidecard button {
  margin: 8px 0;
  width: max-content;
}
.card.service-group button.button {
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  h2.title.xs-mb {
    margin-bottom: 2rem;
  }
}
.card-header {
  background-color: inherit;
  border-bottom: 0;
}

@media screen and (min-width: 1199px) {
  .cta__wrap.content-end {
    justify-content: flex-start;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 1200px) {
  .cta__wrap.content-end {
    justify-content: flex-end;
  }
}

.card.solution .card-body .card-content > a {
  display: none;
}

.card.solution:hover .card-body .card-content > a {
  display: block;
}

.card-header.flex-direction-column {
  flex-direction: column-reverse;
}
.pagination-wrapper .pagination li.page-number.active a.page-link {
  border: none;
  border-bottom: 2px solid #5c4bb9;
  color: #5c4bb9;
  padding: 5px;
  background: none !important;
  --bs-pagination-padding-x: 0rem;
  --bs-pagination-padding-y: 0rem;
}
.page-item:not(:first-child) .page-link {
  border: none;
}
.page-item:first-child .page-link {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.page-item:last-child .page-link {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  background-color: $white;
}

.card__offering.card-offerings-solution .card-body > div {
  display: block;
}
.offerings-solution-title.footer-action {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 116.667% */
  letter-spacing: -0.5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
.card.footer-action.card-offerings-solution {
  padding: 3rem 3rem 4.25rem 3rem;
  @include respond-below(lg) {
    padding: 1.75rem 1.75rem 3rem 1.75rem;
  }
}
.card.footer-action.card-offerings-solution h2.offerings-solution-title {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 116.667% */
  letter-spacing: -0.5px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
  @include respond-below(lg) {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
}
.card.footer-action.card-offerings-solution .card-body {
  flex-direction: column;
}
.cta__wrap.content-end {
  margin: 0;
  @include respond-below(md) {
    justify-content: flex-start;
  }
  @include respond-above(md) {
    justify-content: flex-end;
  }
}

.card.footer-action.card-offerings-solution .card-body .cta__wrap {
  width: 100%;
}
.card__offering.card-offerings-solution .card-body > div.tags {
  display: flex;
}
.card__offering.card-offerings-solution .card-body > div.tags .button.tag {
  margin: 2px 1px;
}
label.small {
  color: $color-lightgray;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  align-self: center;
  padding-bottom: 0.75rem;
}

label.small-offering {
  font-size: 12px;
  font-family: $font-family-2;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  padding-left: 0.25rem;
}

label.related-services {
  display: block;
  width: 100%;
  padding-bottom: 0.5rem !important;
}
.card__listing {
  button {
    &::before {
      background-color: $black;
      height: 0.0625rem;
      bottom: -0.0625rem;
    }

    &:hover:before {
      background-color: $ts-accent-1-primary;
      height: 0.25rem;
      bottom: -0.25rem;
    }
  }
  &.card-header {
    margin-bottom: 2rem;
  }
}
.card.footer-action {
  &.primary {
    background-color: $ts-background-primary;
    color: $ts-background-on-primary-1;
    .title,
    .name,
    .sub-title {
      color: $ts-background-on-primary-1;
    }

    .cta__wrap .button {
      color: $ts-action-on-action-primary;
      background-color: $ts-accent-1-primary;
      &:before {
        border-color: $ts-accent-1-primary;
        background-color: $ts-background-primary;
      }
      &:hover {
        &:before {
          opacity: 0.4;
        }
      }
    }
  }

  &.secondary {
    background-color: $ts-background-secondary;
    color: $ts-background-on-secondary-1;
    .title,
    .name,
    .sub-title {
      color: $ts-background-on-secondary-1;
    }

    .cta__wrap .button {
      color: $ts-background-on-primary-1;
      background-color: $ts-container-on-container-secondary-1;
      &:before {
        border-color: $ts-background-on-secondary-1;
        background-color: $ts-container-on-container-secondary-1;
      }
      &:hover {
        &:before {
          opacity: 0.4;
        }
      }
    }
  }
}

.card-body-image-carousel {
  width: 13.75rem !important;
  height: 17.25rem !important;
  position: relative;
  .article-image-object-fit {
    object-fit: cover !important;
  }

  @include respond-below(md) {
    width: 100% !important;
    height: auto !important;
  }
}
.body-card-button {
  position: absolute;
  left: 24px;
  top: 24px;
  border: none;
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 40px;
  background: #fff;
}
.card-body-image-carousel img {
  width: 13.75rem !important;
  height: 17.25rem !important;
  @include respond-below(md) {
    width: 100% !important; //do not change
    height: auto !important;
  }
}
.card-body-carousel {
  width: 24.1875rem !important;
  height: 17.25rem !important;
  padding: 2rem 1.5rem 1.5rem 1.5rem !important;
  @include respond-below(md) {
    width: 100% !important; //do not change
    height: auto !important;
  }
}
.card__insights-item {
  width: 19.75rem;
  height: 17.25rem !important;
}
.card-global {
  border-radius: 40px;
  border: 1px solid #a3aaaf !important;
  padding: 4px 12px !important;
  background-color: var(--tokens-container-on-background-primary, #fff);
  font-size: 0.75rem !important;
  line-height: 0.75rem !important;
}

.cta__wrap__offering {
  margin-top: 1.25rem;
}

.sub-title-carousel {
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  color: #67696f;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  max-width: 250px;
}

.card-body-offer {
  padding: 3rem 3rem 4.25rem 3rem;
  @include respond-below(lg) {
    padding: 1.75rem 1.75rem 3rem 1.75rem;
  }
}

.card__featured .card-body .card-content p.clamp-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0;
}
p.clamp-3 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
p.clamp-5 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
h3.title.clamp-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.card-body .card-content-carousel .para div p {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 0;
}
a.card {
  &.primary {
    .card-body .card-content-carousel .para div p {
      color: #000;
    }
  }
  &.secondary {
    .card-body .card-content-carousel .para div p {
      color: #fff;
    }
  }
}
.card__featured .card-body.featurette {
  @include respond-below(md) {
    padding: 2rem 1.5rem 1.5rem;
  }
}
.card.card__insights.card__callout {
  .card-body {
    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      @include respond-below(md) {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.5px;
      }
    }
  }
}
.card-body.customer-outcomes-carousel {
  .tags {
    &.customer-outcomes {
      margin-bottom: 24px;
      @include respond-below(sm) {
        margin-bottom: 16px;
      }
    }
  }
}
.card-content-service.no-padding-bottom {
  padding-bottom: 0 !important;
}
.card__callout .card-body .cta__wrap.flex-row {
  flex-direction: row;
}
.card__callout .card-body .cta__wrap.flex-row a:last-child {
  margin-left: 1.25rem;
}
.card.quote.secondary > .card-body {
  color: $white !important;
}

.card-block-country-profile {
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
  padding: 60px 80px;
  @include respond-below(lg) {
    padding: 60px 20px;
  }
  .country-profile .card-body div img.headshot{
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  }
}
.country-profile-a {
  text-decoration: none;
}

.card_frame {
  background-color: black;
  padding: 12px 20px;
  border-radius: 10px;
  width: -webkit-fill-available;
  background: rgba(255, 255, 255, 0.05);
  .card-body {
    padding: 20px 0;
    .sub-title {
      color: $white;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .title {
      color: $white;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 120% */
      letter-spacing: -0.1px;
      padding-bottom: 6.5px;
    }
    .description,
    .link {
      color: $color-footergray;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px; /* 120% */
      letter-spacing: -0.1px;
    }
  }
}
.card-header.mb-24 .cttagcontainer {
  margin-bottom: 24px;
}
.card__insights .card-body .card-content .title.mb-12 {
  margin-bottom: 12px;
}
.card-block.benefits__4 .card.flex-fitcontent {
  @include respond-below(sm) {
    flex-basis: calc(100% - 2rem);
  }
}
