@import '../../../../scss/colors';
@import '../../../../scss/mixins';
@import '../../../../scss/variables';

$btn-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);

.dt-cta {
  position: fixed;
  bottom: 28px;
  right: 20px;
  display: block;
  z-index: 99; // 1000;

  @include respond-above(md) {
    bottom: 40px;
    right: 40px;
  }

  .docs-story & {
    position: absolute;
  }

  &__collapsed {
      display: block;
      opacity: 1;
    .dt-cta__label {
      display: none;
      opacity: 0;
    }
  }

  &__wrapper {
    @include respond-above(md) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      // padding-right: 20px;
      backdrop-filter: blur(10px);
      border-radius: 40px;
      background: linear-gradient(
        to right,
        $ts-accent-2-primary 22px,
        $ts-action-primary 22px
      );
    }
    img{
      width: 40px !important;
      height: 40px !important;
      max-width: 2.5rem;
      max-height: 2.5rem;
    }
  }

  &__button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    background: $ts-accent-2-primary;
    border-radius: 40px;
    border: none;

    &:before {
      content: '';
      position: absolute;
      top: -0.25rem;
      left: -0.25rem;
      height: calc(100% + 0.5rem);
      width: calc(100% + 0.5rem);
      border-radius: 2.5rem;
      border: 0.25rem solid $ts-accent-2-on-accent-2-primary;
      background-color: $ts-accent-2-on-accent-2-primary;
      transition: $btn-transition;
      opacity: 0;
      z-index: -1;
    }

    &:hover {
      &:before {
        opacity: 0.2;
      }
    }

    @include respond-above(md) {
      width: 44px;
      height: 44px;
    }

    img {
      width: 36px;

      @include respond-above(md) {
        width: 28px;
      }
    }
  }

  &__label {
    position: absolute;
    width: 0%;
    height: 0px;
    display: block;
    opacity: 0;
    padding-right: 20px;
    visibility: hidden;
    white-space: nowrap;
    transition: width 1s ease-in-out, padding-left 1s ease-in-out, padding-right 1s ease-in-out;
    cursor: pointer;

    @include respond-above(md) {
      position: relative;
      opacity: 1;
      visibility: visible;
      margin-bottom: 0;
      width: 100%;
      height: auto;
      font-family: $font-copy;
      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.25rem;
      color: $ts-action-on-action-primary;
    }
  }
}
