@import './../../../scss/colors';
@import './../../../scss/mixins';
@import './../../../scss/fonts';
@import './../../../scss/base';
@import './../../../globals/typography/typography.scss';
@import './../../../scss/variables';

.banner-section {
  position: relative;
  .content-wrapper {
    .on-primary {
      color: $black;
    }
    @include mobile {
      .text-button {
        &.white {
          border-bottom: 1px solid $white;
        }
        &.black {
          border-bottom: 1px solid $black;
        }
      }
    }
  }
  &.standard {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: $ts-color-gray-25;
    @include respond-between(md, xl) {
      // padding: 0 4rem;
    }
    @media screen and (min-width: 1700px) {
      height: 80vh;
    }
    @include respond-above(md) {
      background: unset;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    &.PageBannerL2 {
      padding: 5.5rem 4.875rem;
      @include mobile {
        padding: 0;
      }
      @include respond-above(sm) {
        padding: 0;
      }
      .PageBannerL2-container {
        @include mobile {
          // padding: 2.5rem 1.25rem;
        }
        .frost__ {
          padding: 0;
          .breadcrumb {
            margin: 0 0 2rem 0;
            @include mobile {
              margin: 0 0 1.75rem 0;
            }
          }
          .sub-title {
            margin: 0 0 2rem 0;
            @include mobile {
              margin: 0 0 1.75rem 0;
            }
          }
        }
      }
      &.glass {
        @include respond-above(md) {
          padding: 4rem 5rem 4rem 2.5rem;
        }
        .frost {
          padding: 1.5rem 2.5rem;
        }
      }
    }
    .banner-image {
      // object-fit: fill;
      object-position: right;
      width: 100%;
      height: 100%;

      @include respond-above(md) {
        position: absolute;
        top: 0;
        left: 0;
        @include respond-below(md) {
          // object-fit: cover;
        }
        z-index: -2;
        border: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content-wrapper {
      padding: 2.5rem 1.25rem 3rem;
      min-width: 100%;

      @include respond-between(md, xl) {
        padding: 5rem;
      }
      @include respond-below(md) {
        padding: 2.5rem 1.25rem 2.5rem;
      }
      @include respond-above(xl) {
        padding: 5rem;
      }
      @media (min-width: 992px) and (max-width: 1140px) {
        padding: 4rem 5rem 5rem;
      }
      .breadcrumb {
        &.secondary {
          color: $ts-background-on-secondary-1;
        }
        @include respond-below(md) {
          margin: 0 0 1.75rem 0;
        }
        margin: 0 0 2rem 0;
      }
      .title {
        @include headline(2);
        //  margin-bottom: 0.75rem;
        @include respond-below(lg) {
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 36px; /* 112.5% */
          letter-spacing: -0.5px;
        }
        &.secondary {
          color: $ts-background-on-secondary-1;
        }
      }
      .sub-title {
        @include respond-below(md) {
          margin: 1.25rem 0 1.75rem 0;
        }
        margin: 1.25rem 0 2rem 0;
        &.secondary {
          color: $ts-background-on-secondary-1;
        }
        &.on-secondary {
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          color: $white;
          div {
            line-height: 22px;
          }
        }
      }
      .cta__wrap {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        @include respond-above(md) {
          flex-direction: row;
          align-items: center;
          padding-bottom: 1.625rem;
        }
        @media (min-width: 992px) and (max-width: 1300px) {
          padding-bottom: 0;
        }
        .button {
          &:first-child {
            margin: 0 0 2rem 0;
            @include respond-above(md) {
              margin: 0 2.5rem 0 0;
            }
          }
          &:only-child {
            margin: 0;
          }
        }
      }
    }
    &.banner {
      &__contact {
        @include respond-above(md) {
          min-height: 31.25rem;
        }
        .form__wrap {
          max-width: 90%;
          @include respond-above(md) {
            max-width: 80%;
          }
          @include respond-above(lg) {
            max-width: 60%;
          }
          .form-block-wrapper {
            .title {
              @include label(medium-bold);
              margin: 0 0 0.25rem 0;
            }
          }
        }
      }
      &__solutions {
        @include respond-above(md) {
          min-height: 33.75rem;
          // min-height: 24rem;
        }
      }
      &.hero-contact-banner {
        @include respond-above(md) {
          min-height: 0;
          height: 19.25rem;
          padding: 5rem 4.25rem 3.625rem 4.25rem;
          justify-content: unset;
          flex-flow: column;
        }
        .PageBannerL2-container {
          margin: 0;
          .content-wrapper {
            @include respond-above(md) {
              margin: 0;
              padding: 0;

              .breadcrumb {
                margin-bottom: 1.75rem;
              }
              .sub-title {
                margin: 0.5rem 0 0 0;
              }
            }
          }
        }
      }
      &__subpage {
        @include respond-above(md) {
          min-height: 31.25rem;
        }
      }
    }
    &.contact-banner {
      .content-wrapper {
        margin: 0;
        padding: 2.5rem 1.25rem;
        .frost {
          padding: 2.25rem 2.5rem;
        }
        .sub-title {
          margin: 0.5rem 0 1.75rem 0;
          @include respond-below(md) {
            margin: 0 0 1.5rem 0;
          }
        }
        .form__wrap {
          .form-block {
            margin-bottom: 1.25rem;
          }
        }
      }
      .breadcrumb {
        margin: 0 0 1.75rem 0;
        @include respond-below(md) {
          margin: 0 0 1.5rem 0;
        }
      }
    }
  }
  &.services {
    padding: 0;
    min-height: 34rem;
    .content-wrapper {
      @include respond-above(md) {
        padding: 5.5rem 4.875rem;
      }
      @media (min-width: 992px) and (max-width: 1300px) {
        padding: 1.5rem 2.5rem;
      }
      .cta__wrap {
        .text-button {
          @include mobile {
            margin: 0;
          }
        }
      }
    }
  }
  &.image {
    min-height: 24rem;
    @include respond-above(md) {
      min-height: 34rem;
    }
    .banner-image {
      @include respond-below(md) {
        position: absolute;
        // object-fit: cover;
      }
    }
    .content-wrapper {
      margin: 2rem 0 4rem;
      @include respond-above(md) {
        min-width: 1200px;
        margin: 5.5rem 0;
      }
      .frost {
        padding: 1.25rem 1.25rem 1.75rem 1.25rem;
        @include respond-above(md) {
          padding: 3rem;
        }
      }
      .sub-title {
        margin: 1.25rem 0 0;
      }
      .cta__wrap {
        align-items: center;
        margin: 1.75rem 0 0;
        @include respond-above(md) {
          justify-content: center;
          margin: 2.5rem 0 0;
        }
      }
    }
  }
  &.insights {
    @include respond-below(md) {
      background-color: $white;
    }

    .banner-image {
      border: 0;
      @include respond-above(md) {
        height: 100%;
      }
    }
    .content-wrapper {
      @include respond-above(md) {
        padding: 0;
      }
      @include respond-between(md, xl) {
        padding: 0 4rem;
      }
      @include respond-below(md) {
        padding: 2.5rem 0.5rem;
        margin: 0;
      }
      .breadcrumb {
        @include respond-below(md) {
          margin: 0 0 1.5rem 0;
        }
      }
      .L3Content {
        @include respond-above(md) {
          padding: 0;
        }
      }
      .L3Image {
        @include respond-above(md) {
          padding: 0;
        }
        &.image-wrapper img {
          @include respond-below(md) {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .carousel {
      background: $ts-color-gray-25;
      @include respond-below(xl) {
        padding-right: 0;
        .carousel-wrapper {
          padding-right: 0;
        }
      }
      @include respond-below(sm) {
        .carousel-wrapper {
          padding: 0;
        }
      }
      &.insights {
        padding: 2.5rem 0 5rem 2.5rem;
        &.secondary {
          background-color: rgba(0, 0, 0, 0.7);
          .section-header .title.secondary,.section-header .sub-title.secondary {
            background-color: transparent;
          }
          .section-header .sub-title.secondary {
            margin-top: 0;
            margin-bottom: 3.125rem;
          }
          .carousel-wrapper.related-insights {
            .card-body.card-body-carousel.related-insights-carousal,
            .card.card__insights.featured {
              background-color: $color-darkgray;
            }
            .card-body.related-insights-carousal .card-content-carousel .mobtitle {
              color: $white;
              line-height: 24px;
            }
          }
        }
      }
    }
    &.standard.banner.insights {
      @include respond-above(md) {
        min-height: 800px;
        max-height: 800px;
      }
    }
    @include respond-above(md) {
      padding: 8.5rem 0 0;
      flex-direction: column;

      .carousel {
        width: 100%;
        margin: 8rem 0 0;
        .section-header {
          h2.title {
            font-family: Inter;
            font-size: 32px;
            font-weight: 400;
            line-height: 38px;
            letter-spacing: -0.5px;
            margin-bottom: 33px;
          }
        }
        .carousel-wrapper.related-insights .slick-slider {
          .slick-list {
            height: 276px;
          }
        }
      }
      &.primary {
        .carousel {
          background: rgba(255, 255, 255, 0.5);
          .card {
            background-color: $white;
          }
        }
      }
      &.secondary {
        .carousel {
          // background: $white;
          .card {
            // background-color: $white;
          }
        }
      }
    }
    &.subpage {
      padding: 0;
      @include respond-between(md, xl) {
        padding: 0 5rem;
      }
      @include respond-below(md) {
        padding: 2.5rem 0.5rem;
      }
      .content-wrapper {
        align-items: center;
        padding: 1.25rem 0;
        @include respond-below(md) {
          padding: 0;
        }
        @include respond-above(md) {
          padding: 2.5rem 0;
        }
        .pagebannerl3-tag {
          display: flex;
          margin-top: 1.5rem;
          button {
            margin-right: 0.25rem;
          }
        }
        .breadcrumb {
          @include respond-below(md) {
            margin-bottom: 1.5rem;
          }
          margin-bottom: 2.5rem;
        }
        .sub-title {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 116.667% */
          letter-spacing: -0.5px;
          // @include headline(medium);
          @include respond-below(md) {
            font-size: 16px;
            line-height: 22px; /* 120% */
            letter-spacing: -0.1px;
          }
        }
        .title {
          @include headline(3);
        }
        .title_l3 {
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: 42px; /* 116.667% */
          letter-spacing: -1px;
          margin-bottom: 1.25rem;
          @include respond-below(md) {
            font-size: 28px;
            line-height: 34px; /* 121.429% */
            letter-spacing: -0.5px;
          }
        }
        .large {
          h3 {
            @include respond-below(md) {
              line-height: 111%;
            }
          }
        }
        .large_l3 > * {
          font-size: 2.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: 42px; /* 116.667% */
          letter-spacing: -1px;
          @include respond-below(md) {
            font-size: 28px;
            line-height: 34px; /* 121.429% */
            letter-spacing: -0.5px;
          }
        }
        .content {
          @include respond-below(md) {
            margin-bottom: 1.5rem;
          }
          p {
            margin: 0.75rem 0;
          }
        }
        .image-wrapper {
          @include respond-below(sm) {
            text-align: center;
          }
          img {
            @include respond-above(md) {
              width: 100%;
              height: 100%;
              // object-fit: cover;
              object-position: right;
              aspect-ratio: 1;
              border-radius: 8px;
              max-width: 412px;
              max-height: 412px;
            }
          }
          .banner-image {
            border-radius: $ts-card-border-radius;
            margin: 1.5rem 0 0 0;
            max-width: 75%;
            @include respond-above(md) {
              margin: 0 0 0 4rem;
              max-width: calc(100% - 4rem);
            }
          }
        }
        .content-type {
          margin-top: 2.5rem;
          color: $ts-background-on-primary-2;
          font-weight: 600;
          .type {
            text-transform: capitalize;
            margin-bottom: 0.5rem;
          }
        }
        &.left {
          flex-direction: row-reverse;
          margin: 0px;
          .banner-image {
            @include respond-above(md) {
              margin: 0 4rem 0 0;
            }
          }
        }
      }
    }
  }
  &.services {
    .banner-image {
      @include respond-between(md, lg) {
        // min-height: 544px;
      }
    }
    .content-wrapper {
      @include mobile {
        padding: 2.5rem 1.25rem;
      }

      @include respond-between(sm, md) {
        padding: 2.5rem 1.25rem;
      }

      @include respond-above(md) {
        //padding: 5.5rem 1.275rem;
      }
      .sub-title {
        @media (min-width: 992px) and (max-width: 1300px) {
          width: 100%;
        }
        @media (min-width: 1300px) {
          width: 70%;
        }

        margin: 1.25rem 0 1.75rem 0;
      }
      .service-provider {
        margin: 0;
        padding: 0;
        width: fit-content;
        a {
          @include label(medium-bold);
          color: $ts-background-on-primary-1;
          text-decoration: none;
        }
        &.on-secondary a {
          color: $ts-background-on-secondary-1;
        }
      }
      .related-content {
        padding: 0.5rem 0 0;
        .tag-wrapper-banner {
          .button {
            &.on-primary {
              &.small {
                @include mobile {
                  color: #fff;
                  background-color: #282a32;
                }
              }
            }
            &.on-secondary {
              &.small {
                @include mobile {
                  color: #282a32;
                  background-color: #fff;
                }
              }
            }
          }
        }
        .title {
          margin: 0;
          @include label(medium);
        }
        .tag-wrapper {
          .tag {
            margin: 0 0.5rem 1rem 0;
          }
        }
      }
    }
  }
  &.home-banner-block {
    // position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
    //min-height: 23.75rem;
    @include respond-below(md) {
      padding: 0;
    }
    @include respond-above(md) {
      //  padding: 0 11.813rem;
    }
    @include respond-above(md) {
      align-items: center;
      // min-height: 43.75rem;
    }
    @media screen and (min-width: 1700px) {
      height: 80vh;
    }
    &.glass {
      align-items: flex-start;
      padding: 0 0 6.625rem;
      @include respond-above(md) {
        align-items: flex-end;
        padding: 0 5.625rem;
      }
      @include respond-below(md) {
        .no-mobile-padding {
          padding: 0;
        }
      }
      .content-wrapper {
        padding: 8rem 0 0;
        margin: 0;
        @include respond-below(md) {
          padding: 0;
        }
      }
      .frost {
        padding: 8.75rem 1.25rem 2.5rem;
        @include respond-above(md) {
          padding: 6.5rem 2.5rem 5rem;
        }
        @include respond-above(lg) {
          padding: 5rem 3.75rem 7.5rem;
        }
      }
    }
    .banner-image {
      position: absolute;
      top: 0;
      left: 0;
      // object-fit: cover;//commented out by Prabha for accessibility when zoomed in
      width: 100%;
      z-index: -2;
      height: 100%;
      object-fit: cover;
      object-position: right;

      @include desktop {
        // min-height: 700px;
      }
      @include mobile {
        min-height: 700px;
        height:auto
      }
      @media screen and (min-width: 1700px) {
        object-fit: fill;
      }
      @include respond-below(md) {
        // min-height: 700px;
        // object-fit: cover;
      }
      // @include respond-above('2560px') {
      //   top: -20%;
      // }
    }
    .content-wrapper {
      // margin-top: 8.75rem;
      position: relative;

      @media (min-width: 1200px) {
        padding: 11.406rem 5rem;
      }
      min-width: 100%;
      @media (min-width: 963px) and (max-width: 1200px) {
        margin: 0;
        padding: 11.406rem 5rem;
      }
      @media (min-width: 1400px) {
        padding: 11.406rem 0;
      }
      @media (min-width: 768px) and (max-width: 962px) {
        margin: 0;
        padding: 11.406rem 1.25rem;
      }
      @include respond-below(sm) {
        padding: 8.75rem 1.25rem 2.5rem;
        margin: 0;
      }
      .sub-title {
        @include headline(small);
        margin: 0 0 0.75rem 0;
      }
      .description {
        margin: 1rem 0 2.5rem 0;
      }
      .cta__wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include respond-below(sm) {
          align-items: flex-start;
          flex-direction: column;
        }
        .button {
          &:first-child {
            margin: 0 1.25rem 2rem 0;
            @include respond-above(md) {
              margin: 0 2.5rem 0 0;
            }
          }
          &.black:before {
            background-color: $black;
          }
          &:only-child {
            // margin: 0;
          }
        }
      }
    }
  }
  &.glass {
    .frost {
      padding: 1.5rem;
      @include respond-above(md) {
        padding: 4rem 5rem 4rem 2.5rem;
      }
      &__light {
        @include frostedGlass(light);
        padding: 4rem 5rem 4rem 2.5rem;
      }
      &__dark {
        @include frostedGlass(dark);
        padding: 4rem 5rem 4rem 2.5rem;
      }
    }
  }
}
h2.temp {
  margin: 30px 0;
  text-align: center;
}
.banner-section.standard .content-wrapper .cta__wrap a .button:first-child {
  margin: 0 2.5rem 0 0;

  @include mobile {
    margin-top: 0rem;
    margin-bottom: 20px;
  }
  &.medium:before {
    background-color: $black;
  }
  &.white:before {
    background-color: $white;
  }
}

.title-home {
  display: flex !important;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 68px; /* 106.25% */
  letter-spacing: -3.5px;
  @include respond-below(sm) {
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px; /* 108.333% */
    letter-spacing: -2.5px;
    padding: 0;
  }
}

.sub-title-home {
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important; /* 120% */
  letter-spacing: -0.1px !important;
  @include headline(small);
  margin: 0 0 0.75rem 0;
}
a {
  &.delivered div {
    font-weight: 400;
  }
}
.title-home {
  display: flex !important;
  @media (min-width: 1200px) {
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 68px; /* 106.25% */
    letter-spacing: -3.5px;
  }

  @media (max-width: 1200px) {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px; /* 108.333% */
    letter-spacing: -2.5px;
    padding: 0;
  }
  @media (min-width: 768px) and (max-width: 900px) {
    font-size: 2rem;
  }
  @include respond-below(sm) {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px; /* 108.333% */
    letter-spacing: -2.5px;
    padding: 0;
  }

  .sub-title-home {
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important; /* 120% */
    letter-spacing: -0.1px !important;
    @include headline(small);
    margin: 0 0 0.75rem 0;
  }
  &.on-secondary {
    color: #fff;
  }
  &.on-primary {
    color: #000;
  }
}
.title-content-limit,
.para-content-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;

  -webkit-box-orient: vertical;
}
.title-content-limit {
  -webkit-line-clamp: 2;
}

.para-content-limit {
  -webkit-line-clamp: 4;
}

.title-image {
  display: flex !important;
  font-family: $font-family-1;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px; /* 116.667% */
  letter-spacing: -1px;
  margin-bottom: 1.25rem;
  @include respond-below(lg) {
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 116.667% */
    letter-spacing: -1px;
  }

  .sub-title-image {
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 2.5rem;
    @include respond-below(lg) {
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
  &.on-secondary {
    color: #fff;
  }
  &.on-primary {
    color: #000;
  }
}
