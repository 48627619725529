@import '../../../scss/fonts';
@import '../../../scss/mixins';
@import '../../../scss/colors';
@import '../../../scss/variables';
@import '~bootstrap/scss/bootstrap';

.country-search .dp-down {
  .dropdown-menu {
    z-index: 1100;
    background-color: white;
    margin-top: 2rem !important;
    .dropdown-item {
      span {
        padding-right: 5px;
      }
      a {
        padding: 0 5px;
        color: $color-blue;
        color: $mpg-color-violet-75;
        font-family: $font-copy;
        font-size: 1rem;
        font-weight: 400;
        display: inline-block;
      }
      div {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  .item-label {
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: -1.5px;
    margin-bottom: 1.25rem;
    @include mobile {
      font-size: 1.5rem;
    }
  }
  h2 {
    outline: none;
  }
  .dp-down .form-control {
    width: 300px;
  }
  #clear {
    position: relative;
    float: right;
    height: 1.25rem;
    width: 1.25rem;
    top: -37px;
    right: 5px;
    color: white;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    background-image: url('../../assets/icons/country/icon__close--black.svg');
    background-repeat: no-repeat;
    background-color: white;
    border: none;
  }
  #search {
    position: relative;
    float: right;
    height: 1.25rem;
    width: 1.25rem;
    top: -42px;
    right: 0.625rem;
    color: white;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    background-image: url('../../assets/icons/country/icon-search_mag_glass-left.svg');
    background-repeat: no-repeat;
    background-color: white;
    border: none;
  }
  .input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .floating-label {
    margin-top: -25px;
    font-size: 0.75rem;
    @include mobile {
      margin-top: -20px;
    }
  }
  .no-input {
    margin-top: -10px;
    font-size: 1.25rem;
  }

  .form-control {
    height: 64px;
    font-size: 1.25rem;
    padding: 0.75rem 1.25rem;
  }
  .no-data {
    margin: 0px;
    padding: 5px;
    font-weight: 400;
  }
  button {
    outline: none !important;
    &:focus-visible {
      outline: 5px auto -webkit-focus-ring-color !important;
    }
  }
}

div.fade.fullscreen.modal {
  z-index: 1100;
  padding-left: 0px !important;
  // @include small-desktop {
  //   div.modal-body {
  //     right: -17px;
  //   }
  // }
}

// .modal-backdrop.show {
//   opacity: 0 !important;
// }
