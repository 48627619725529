@import './../../../scss/colors';
@import './../../../scss/mixins';

.searchFieldWrapper {
  position: relative;
  display: block;
  border-bottom: 0.0625rem solid $ts-color-gray-75;
}

.searchInputWrapper {
  display: flex;

  input[type='text'] {
    width: 100%;
    border: none;
    font-size: 1.5rem;
    font-weight: 400;
    text-align: left;
    line-height: 2.5rem;
    letter-spacing: -0.0625rem;
    appearance: none;
    @include respond-above(md){
      padding-left: 0.5rem;
    }
    &:focus {
      outline: none;
    }

    @include respond-below(md) {
      font-size: 1.5rem;
      line-height: 2rem;
      letter-spacing: -0.031rem;
    }

    &.typing {
      color: $ts-color-black;
    }
  }
}

.search_btn {
  img {
    width: 1.688rem;
    @include respond-below(md) {
      width: 1.5rem;
    }
  }
}

.suggestions {
  /* drop-down */
  position: absolute;
  z-index: 10;
  top: calc(100% + 0.125rem);

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
  max-height: 60vh;
  padding: 1.25rem;
  border-radius: 0 0 0.75rem 0.75rem;
  background-color: white;
  box-shadow: 0 28.3382px 44.3162px rgba(198, 198, 197, 0.115858),
    0 18.1606px 28.4001px rgba(198, 198, 197, 0.135),
    0 11.7703px 18.4067px rgba(198, 198, 197, 0.154142),
    0 7.40924px 11.5868px rgba(198, 198, 197, 0.175929),
    0 4.25707px 6.65734px rgba(198, 198, 197, 0.20529),
    0 1.87365px 2.93007px rgba(198, 198, 197, 0.27);

  ul {
    left: 0;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style: none;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: #939498; 
      border-radius: 10px;
    }
  }

  li {
    padding: 0.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: $ts-color-gray-50;
    text-align: left;
    line-height: 1.75rem;
    letter-spacing: -0.031rem;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    span {
      font-size: inherit;
      color: $ts-color-black;
    }

    &.activeSuggestion {
      span {
        color: inherit;
      }
      cursor: pointer;
      color: $ts-color-violet-100;
      border-bottom: 1px solid $ts-color-gray-50;
    }
  }
  
}
