@import '../../../../scss/mixins';
@import '../../../../scss/colors';
@import '../../../../scss/variables';

.subnavexpandable-section {
  &.primary {
    background-color: $white;
    color: $black;
  }
  &.secondary {
    background-color: $black;
    color: $white;

    .tabs .nav .nav-item {
      margin-bottom: 1rem;
      box-shadow: none;
      &.active {
        //background-color: rgba(255, 255, 255, 0.05) !important;
        background-color: $ts-color-violet-75 !important;
        box-shadow: none;
        margin-bottom: 0;
      }
      .nav-link {
        background-color: rgba(255, 255, 255, 0.05);
        color: $white;
        margin-bottom: 0;
        border: unset;
        &::after {
          color: $white;
        }
      }
    }
    .tabs .tab-content .tab-pane {
      // background-color: #000;
      background: rgba(255, 255, 255, 0.05);
      color: $white;
      .sub-title {
        color: $white;
        line-height: 1.125rem;
        font-size: 0.75rem;
      }
      .wrapper {
        .links {
          .data-block__container {
            padding: 64px 44px 44px;
          }
          .button {
            &:hover {
              &:before {
                background-color: $ts-background-primary;
              }
            }
          }
        }
      }
    }
    .accordion-section {
      background-color: rgba(255, 255, 255, 0.05);

      .accordion-body button.white {
        border-bottom: 1px solid $white;
      }
      .accordion-block {
        // &:hover {
        //   box-shadow: $ts-card-box-shadow-light;
        // }
        .icon {
          background-image: url('../../../../static/icons/icon--plus-white.svg');
        }
        &.active {
          .icon {
            background-image: url('../../../../static/icons/icon--minus--white.svg');
          }
        }
      }
    }
  }
  padding: 3.75rem 0;
  @include mobile {
    padding: 3.75rem 1.25rem;
  }
  @include respond-between(md, xl) {
    padding: 5rem;
  }
  &.no-padding {
    padding: 0 !important;
  }
  .section-header {
    padding: 0 0 3.75rem;
    @include mobile {
      padding: 0 0 2.5rem;
    }
    .grid-title {
      h2 {
        margin-bottom: 0.75rem;
        @include mobile {
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 125% */
          letter-spacing: -0.5px;
        }
      }
      h1 {
        font-weight: 400;
        line-height: 44px;
      }
      p {
        margin: 0;
        color: $ts-background-on-secondary-2;
      }
    }
  }
  .section-footer {
    margin-top: 1.75rem;
    @include mobile {
      margin-top: 2.5rem;
    }
    .grid-footer {
      padding: 1.25rem;
      @include mobile {
        margin: 0;
        padding: 0 1.25rem;
      }
      h5 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 120% */
        letter-spacing: -0.1px;
      }
      p {
        margin: 0;
        color: $ts-background-on-secondary-2;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        @include mobile {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
      }
    }
  }
  .accordion-section {
    margin: 0;
    padding: 0;
    .accordion-block {
      border: 0;
      margin: 0 0 1rem 0;
      padding: 1.25rem;
      border-radius: $ts-card-border-radius;
      // box-shadow: $ts-card-box-shadow-light;
      transition: $btn-transition;
      background-color: rgba(255, 255, 255, 0.01);
      &:first-child {
        border-top: 0;
      }
      &:hover {
        box-shadow: $ts-card-box-shadow-light-hover;
      }
      .accordion-title {
        @include body-copy(medium);
        font-family: $font-family-2;
        position: relative;
        display: flex;
        align-items: center;
        img {
          margin-right: 1rem;
          width: 32px;
        }
        @media screen and (max-width: 768px) {
          img {
            width: 32px;
          }
        }
      }
      .icon {
        background-image: url('../../../../static/icons/icon--plus.svg');
        background-repeat: no-repeat;
        background-size: 1rem;
        width: 1rem;
        height: 1rem;
        margin: 0;
        position: absolute;
        right: 0;
        top: 25%;
        &:after {
          content: none;
        }
      }
      &.active {
        .icon {
          background-image: url('../../../../static/icons/icon--minus.svg');
        }
      }
      .accordion-body {
        margin: 2.5rem 0.5rem 1.5rem;
        .title {
          margin-bottom: 0.5rem;
        }
        .sub-title {
          margin: 0;
          @include mobile {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
        .cta__wrap {
          margin: 1.25rem 0 0 0;
          @include mobile {
            margin: 1.25rem 0 1.5rem 0;
          }
        }
        .related-solution {
          display: none;
        }
        .wrapper {
          position: relative;
        }
        .links {
          position: relative;
          display: flex;
          flex-direction: column;
          .button {
            padding: 0.75rem 0;
            margin: 0;
            .arrow-icon {
              margin-left: auto;
            }
          }
          .data-block {
            .data-block__container {
              @include mobile {
                padding: 28px 20px 20px 20px;
              }
            }
          }
        }

        .absolute-block {
          position: absolute;
          z-index: 1;
          top: 40px;
          //background-color: #5C4BB9;
          color: #ffff;
          border-radius: 0.906em;
          height: 1.813rem;
          padding-left: 1.5em;
          padding-right: 0.5em;
          cursor: pointer;
          border-color: $ts-color-violet-75;
          .search_filter_wrapper {
            .filterDropdown {
              position: relative;
              isolation: isolate;
              .button.text-button.small {
                width: 1.75rem !important;
              }
              &.disabled {
                cursor: not-allowed;
                opacity: 0.5;
              }

              @include respond-below(md) {
                max-width: 100%;
              }

              .filterDropdownTitle {
                position: relative;
                border-radius: 16px;
                padding: 0.5rem;
                height: 32px;
                border-width: 1px;
                min-width: 112px;
                border-style: solid;
                padding-top: 2px;
                display: flex;
                align-items: flex-start;
                gap: 10px;
                flex: 1 0 0;
                justify-content: space-between;
                cursor: pointer;
                background-color: $ts-color-white;
                color: $ts-color-black;

                &:after {
                  position: absolute;
                  top: 50%;
                  right: 20px;
                  transform: translateY(-50%);
                  // Black chevron down
                  content: '';
                  background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 1.25L9 8.75L1.5 1.25' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  width: 15px;
                  height: 7.5px;
                }

                &.disabled {
                  pointer-events: none;
                }
              }

              &.dropdown_open {
                z-index: 20;
                width: 112px;
                .filterDropdownTitle {
                  background-color: $ts-color-violet-75;
                  color: $ts-color-white;
                  @include mobile {
                    width: 112px;
                    border-color: $ts-color-violet-75;
                  }
                  &:after {
                    // White chevron up
                    content: '';
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 9.75L9 2.25L16.5 9.75' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A") !important;
                  }
                }

                .filter_options__Dropdown {
                  display: none;
                  background-color: black;
                  .dropdown__item .form-block.checkbox:hover,
                  .dropdown__item_all .form-block.checkbox:hover {
                    background: #0000;
                  }
                  @include mobile {
                    margin-top: 36px;
                  }
                  .filter_options__Dropdown__bottom {
                    background-color: $black;
                    .dropdown__item_all {
                      margin-bottom: 0;
                    }
                  }
                }
                &:hover {
                  .filter_options__Dropdown {
                    display: block;
                  }
                }
              }

              &.dropdown_closed {
                .filterDropdownTitle {
                  border-color: $ts-color-black;
                  display: flex;
                  width: 15.563rem;
                  padding: 0.688rem 1.25rem;
                  align-items: center;
                  gap: 1.25rem;
                  flex-shrink: 0;
                  border-color: $ts-color-gray-50;

                  &[data-filter-count]:not([data-filter-count='0']) {
                    background-color: $ts-color-gray-25;
                    border-color: $ts-color-gray-25;
                    padding-left: 50px;

                    &:before {
                      position: absolute;
                      top: 50%;
                      left: 20px;
                      transform: translateY(-50%);

                      content: attr(data-filter-count);
                      background-color: $ts-color-violet-75;
                      color: $ts-color-white;
                      border-radius: 50%;
                      width: 18px;
                      height: 18px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    }
                  }
                }

                .filter_options__Dropdown {
                  display: none;
                }
              }
            }
          }
          @include mobile {
            top: 98px;
            left:0;
          }
        }
      }
    }
  }
}
.subnavexpandable-section {
  .tabs {
    filter: none;
    .nav {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      border-bottom: none;

      @include respond-above(md) {
        flex-flow: row;
      }
      .nav-item {
        display: flex;
        flex-grow: 1;
        text-align: center;
        list-style-type: none;
        margin-right: 1rem;
        border-radius: $ts-card-border-radius;
        flex-basis: calc(25% - 1rem);
        cursor: pointer;
        z-index: 3;
        &.active {
          background-color: $ts-background-primary;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        .nav-link {
          @include body-copy(medium);
          font-family: $font-heading;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          flex-grow: 1;
          margin-bottom: 1rem;
          border-radius: $ts-card-border-radius;
          background-color: $ts-background-primary;
          color: $ts-background-on-primary-1;
          width: 100%;
          text-decoration: none;
          text-align: left;
          padding: 0.75rem;
          position: relative;
          z-index: 2;
          font-size: 1.25rem;
          line-height: 1.5rem;
          &::after {
            content: '+';
            height: 2rem;
            width: 10px;
            font-size: 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            color: $ts-background-secondary;
            margin-left: auto;
            width: fit-content;
          }
          &.active {
            background-color: $ts-color-violet-75;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            box-shadow: none;
            &::after {
              content: '—';
              font-size: 1.25rem;
              font-weight: 700;
            }
          }
          .icon {
            height: 1.75rem;
            width: 1.75rem;
            margin-right: 0.75rem;
          }
        }
      }
    }

    .tab-content {
      position: relative;
      .tab-pane {
        display: none;
        background-color: $ts-background-primary;
        border-radius: $ts-card-border-radius;
        padding: 3.75rem;
        &:first-child {
          border-top-left-radius: 0;
        }
        &:last-child {
          border-top-right-radius: 0;
        }
        &.active {
          display: block;
          .flex-container {
            .flex-container1 {
              width: 35%;
              border-right: 1px solid $color-lightgray;
              padding-right: 60px;
            }

            .flex-container2 {
              width: 65%;
              padding: 0 0 0 60px;
              .absolute-block .filter_options__Dropdown__bottom{
                background-color: $black;
              }
            }
            display: flex;
            flex-direction: row;
          }
        }
        img {
          width: 5rem;
          height: 5rem;
          margin-bottom: 0.75rem;
        }
        .title {
          @include headline(5);
          margin-bottom: 0.75rem;
          &.grid-title {
            line-height: 34px;
          }
        }
        .sub-title {
          color: $ts-background-on-primary-2;
          font-family: $font-family-2;
        }
        .cta__wrap {
          margin-top: 3rem;
          .buttonhref-anchor {
            .button {
              font-size: 1rem;
            }
          }
          .button.small {
            border-bottom: 1px solid $color-lightgray;
          }
        }

        .wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
          position: relative;
          .label {
            margin-bottom: 3rem;
          }
          .links {
            position: relative;
            display: block;
            grid-template-columns: 1fr 1fr;
            gap: 0 2rem;
            align-items: flex-end;
            height: fit-content;
            // margin-top: auto;
            .button {
              margin: 0;
              padding: 0.75rem 0px;
              text-align: start;
              &:hover {
                &:before {
                  height: 0.0625rem;

                  bottom: -0.0625rem;
                }
              }
              .arrow-icon {
                margin-left: auto;
              }
            }
            .button.text-button:before {
              background-color: $color-lightgray !important;
            }
          }
          .absolute-block {
            z-index: 1;
            position: absolute;
            top: 66px;
            right: 0;
            //background-color: #5C4BB9;
            color: #ffff;
            border-radius: 0.906em;
            height: 1.813rem;
            padding-right: 3em;
            cursor: pointer;
            line-height: 1rem;

            .search_filter_wrapper {
              .filterDropdown {
                position: relative;
                isolation: isolate;
                .button.text-button.small {
                  width: 1.75rem !important;
                }
                &.disabled {
                  cursor: not-allowed;
                  opacity: 0.5;
                }

                @include respond-below(md) {
                  max-width: 100%;
                }

                .filterDropdownTitle {
                  position: relative;
                  border-radius: 16px;
                  padding: 0.5rem;
                  height: 32px;
                  border-width: 1px;
                  min-width: 112px;
                  border-style: solid;
                  padding-top: 2px;
                  display: flex;
                  align-items: flex-start;
                  gap: 10px;
                  flex: 1 0 0;
                  justify-content: space-between;
                  cursor: pointer;
                  background-color: $ts-color-white;
                  color: $ts-color-black;

                  &:after {
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%);
                    // Black chevron down
                    content: '';
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 1.25L9 8.75L1.5 1.25' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 15px;
                    height: 7.5px;
                  }

                  &.disabled {
                    pointer-events: none;
                  }
                }

                &.dropdown_open {
                  z-index: 20;
                  .filterDropdownTitle {
                    background-color: $ts-color-violet-75;
                    color: $ts-color-white;
                    border-color: $ts-color-violet-75;
                    &:after {
                      // White chevron up
                      content: '';
                      background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 9.75L9 2.25L16.5 9.75' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A") !important;
                    }
                    &:after {
                      // White chevron up
                      content: '';
                      background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 9.75L9 2.25L16.5 9.75' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A") !important;
                    }
                  }

                  .filter_options__Dropdown {
                    display: none;
                    background-color: black;
                    .dropdown__item .form-block.checkbox:hover,
                    .dropdown__item_all .form-block.checkbox:hover {
                      background: #0000;
                    }
                  }
                  &:hover {
                    .filter_options__Dropdown {
                      display: block;
                    }
                  }
                }

                &.dropdown_closed {
                  .filterDropdownTitle {
                    border-color: $ts-color-black;
                    display: flex;
                    width: 15.563rem;
                    padding: 0.688rem 1.25rem;
                    align-items: center;
                    gap: 1.25rem;
                    flex-shrink: 0;
                    border-color: $ts-color-gray-50;

                    &[data-filter-count]:not([data-filter-count='0']) {
                      background-color: $ts-color-gray-25;
                      border-color: $ts-color-gray-25;
                      padding-left: 50px;

                      &:before {
                        position: absolute;
                        top: 50%;
                        left: 20px;
                        transform: translateY(-50%);

                        content: attr(data-filter-count);
                        background-color: $ts-color-violet-75;
                        color: $ts-color-white;
                        border-radius: 50%;
                        width: 18px;
                        height: 18px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                      }
                    }
                  }

                  .filter_options__Dropdown {
                    display: none;
                  }
                }
              }
            }
            @include respond-above(xl) {
              right: 0;
            }
          }
        }
      }
      .fade-in {
        animation: fadeIn 0.3s;
      }
    }
  }
}
@keyframes fadeIn {
  from {
    visibility: hidden;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
