.icon {
  $self: &;
  // outline: none; // commented out to be highlighted on tab press
  &.menuModalClose {
    svg {
      use:first-child {
        opacity: 1;
      }
      use:last-child {
        opacity: 0;
      }
    }
    &:hover {
      svg {
        use:last-child {
          opacity: 1;
        }
        use:first-child {
          opacity: 0;
        }
      }
    }
  }
  &.icon {
    @each $button-type in $button-types {
      &__#{$button-type} {
        color: var(--#{$prefix}#{$button-type}-button-txt-color);
        @each $button-mod in $button-mods {
          &--#{$button-mod} {
            @extend .icon__#{$button-type};
            &--list,
            &--signpost,
            &--arrow {
              position: absolute;
              display: inline-flex;
            }
            &--list,
            &--arrow {
              width: 1.125rem;
              height: 1.125rem;
            }

            &-left {
              position: absolute;
              display: inline-flex;
              left: 1.25rem;
              vertical-align: middle;
              color: var(--#{$prefix}#{$button-type}--#{$button-mod}-button-bg-color);
              &-xs {
                @extend .icon__#{$button-type}--#{$button-mod}-left;
                color: var(--#{$prefix}#{$button-type}--#{$button-mod}-button-txt-color);
              }
            }
            &-right {
              position: relative;
              display: inline-flex;
              margin: 0 0 0 0.5rem;
              vertical-align: middle;
              color: var(--#{$prefix}#{$button-type}--#{$button-mod}-button-bg-color);
              &-xs {
                @extend .icon__#{$button-type}--#{$button-mod}-right;
                color: var(--#{$prefix}#{$button-type}--#{$button-mod}-button-txt-color);
              }
            }
            &--list {
              &-left,
              &-right {
                @extend .icon__#{$button-type}--#{$button-mod}--list;
                content: var(--#{$prefix}#{$button-type}--#{$button-mod}--list-icon) !important;
                &:active {
                  content: var(
                    --#{$prefix}#{$button-type}--#{$button-mod}--list-icon-active
                  ) !important;
                }
              }
              &-left {
                @if $button-mod == 'text' {
                  left: 0;
                } @else {
                  left: 1.25rem;
                }
                &.disabled {
                  content: var(
                    --#{$prefix}#{$button-type}--#{$button-mod}--list-icon-disabled
                  ) !important;
                }
                &-md {
                  @extend .icon__#{$button-type}--#{$button-mod}--list-left;
                }
                &-sm {
                  @extend .icon__#{$button-type}--#{$button-mod}--list-left;
                  @if $button-mod == 'text' {
                    left: 0;
                  } @else {
                    left: 1rem;
                  }
                  &.disabled {
                    content: var(
                      --#{$prefix}#{$button-type}--#{$button-mod}--list-icon-disabled
                    ) !important;
                  }
                }
              }
              &-right {
                @if $button-mod == 'text' {
                  right: 0;
                } @else {
                  right: 1.25rem;
                }
                &.disabled {
                  content: var(
                    --#{$prefix}#{$button-type}--#{$button-mod}--list-icon-disabled
                  ) !important;
                }
                &-md {
                  @extend .icon__#{$button-type}--#{$button-mod}--list-right;
                }
                &-sm {
                  @extend .icon__#{$button-type}--#{$button-mod}--list-right;
                  @if $button-mod == 'text' {
                    right: 0;
                  } @else {
                    right: 1rem;
                  }
                  &.disabled {
                    content: var(
                      --#{$prefix}#{$button-type}--#{$button-mod}--list-icon-disabled
                    ) !important;
                  }
                }
              }
            }

            &--arrow {
              &-left,
              &-right {
                @extend .icon__#{$button-type}--#{$button-mod}--arrow;
                &:active {
                  content: var(
                    --#{$prefix}#{$button-type}--#{$button-mod}--arrow-icon-active
                  ) !important;
                }
              }
              &-left {
                left: 0;
                content: var(--#{$prefix}#{$button-type}--#{$button-mod}--arrow-icon) !important;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
                &-md {
                  @extend .icon__#{$button-type}--#{$button-mod}--arrow-left;
                }
                &-sm {
                  @extend .icon__#{$button-type}--#{$button-mod}--arrow-left;
                  &.disabled {
                    content: var(
                      --#{$prefix}#{$button-type}--#{$button-mod}--arrow-icon-disabled
                    ) !important;
                  }
                }
              }
              &.disabled {
                content: var(
                  --#{$prefix}#{$button-type}--#{$button-mod}--arrow-icon-disabled
                ) !important;
              }
              &-right {
                right: 0;
                content: var(--#{$prefix}#{$button-type}--#{$button-mod}--arrow-icon) !important;
                &-md {
                  @extend .icon__#{$button-type}--#{$button-mod}--arrow-right;
                }
                &-sm {
                  @extend .icon__#{$button-type}--#{$button-mod}--arrow-right;
                  &.disabled {
                    content: var(
                      --#{$prefix}#{$button-type}--#{$button-mod}--arrow-icon-disabled
                    ) !important;
                  }
                }
              }
            }

            &--signpost {
              width: 2rem;
              height: 2rem;
              &-left {
                @extend .icon__#{$button-type}--#{$button-mod}--signpost;
                left: 0.5rem;
                top: 0.5rem;
                content: var(--#{$prefix}#{$button-type}--#{$button-mod}--signpost-icon) !important;
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
                &:hover {
                  transition: 0.25s;
                  transform: scale(-1.5, 1.5);
                  content: var(
                    --#{$prefix}#{$button-type}--#{$button-mod}--signpost-icon-hover
                  ) !important;
                }

                &.disabled {
                  @extend .icon__#{$button-type}--#{$button-mod}--signpost-left;
                  content: var(
                    --#{$prefix}#{$button-type}--#{$button-mod}--signpost-icon-disabled
                  ) !important;
                }
                &-md {
                  @extend .icon__#{$button-type}--#{$button-mod}--signpost-left;
                }
                &-sm {
                  @extend .icon__#{$button-type}--#{$button-mod}--signpost-left;
                  width: 1.75rem;
                  height: 1.75rem;
                  top: 0.3rem;
                  left: 0.3rem;
                  &.disabled {
                    @extend .icon__#{$button-type}--#{$button-mod}--signpost-left-sm;
                    content: var(
                      --#{$prefix}#{$button-type}--#{$button-mod}--signpost-icon-disabled
                    ) !important;
                  }
                  &:hover {
                    transition: 0.25s;
                    transform: scale(-1.375, 1.375);
                    content: var(
                      --#{$prefix}#{$button-type}--#{$button-mod}--signpost-icon-hover
                    ) !important;
                  }
                }
              }
              &-right {
                @extend .icon__#{$button-type}--#{$button-mod}--signpost;
                right: 0.5rem;
                top: 0.5rem;
                content: var(--#{$prefix}#{$button-type}--#{$button-mod}--signpost-icon) !important;
                &:hover {
                  transition: 0.25s;
                  transform: scale(1.5);
                  content: var(
                    --#{$prefix}#{$button-type}--#{$button-mod}--signpost-icon-hover
                  ) !important;
                }
                &.disabled {
                  @extend .icon__#{$button-type}--#{$button-mod}--signpost-right;
                  content: var(
                    --#{$prefix}#{$button-type}--#{$button-mod}--signpost-icon-disabled
                  ) !important;
                }
                &-md {
                  @extend .icon__#{$button-type}--#{$button-mod}--signpost-right;
                }
                &-sm {
                  @extend .icon__#{$button-type}--#{$button-mod}--signpost-right;
                  width: 1.75rem;
                  height: 1.75rem;
                  top: 0.3rem;
                  right: 0.3rem;
                  &.disabled {
                    @extend .icon__#{$button-type}--#{$button-mod}--signpost-right-sm;
                    content: var(
                      --#{$prefix}#{$button-type}--#{$button-mod}--signpost-icon-disabled
                    ) !important;
                  }
                  &:hover {
                    transition: 0.25s;
                    transform: scale(1.375);
                    content: var(
                      --#{$prefix}#{$button-type}--#{$button-mod}--signpost-icon-hover
                    ) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
