@import '../../../../scss/fonts';
@import '../../../../scss/mixins';
@import '../../../../scss/colors';

$btn-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
$form-transition: 0.33s cubic-bezier(0.215, 0.61, 0.355, 1);

.hubspot-form-wrapper {
  padding: 3rem 0;
  &.primary,
  &.light {
    background-color: $ts-background-primary; // white
  }
  &.secondary,
  &.dark {
    background-color: $ts-background-secondary; // black
    label,
    .title,
    p.large {
      color: $ts-action-on-action-secondary; // white
    }
  }
  p.large {
    color: $ts-container-on-container-primary-2;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 0 2rem 0;
    @include mobile {
      margin: 0 0 1.25rem 0;
    }
  }
  &.contact {
    .hubspot-form {
      .hs-form-wrapper {
        form {
          .hs-submit {
            background: $ts-accent-1-primary;
            .actions {
              &:after {
                @include signPost(white);
              }
              input[type='submit'] {
                color: $ts-accent-1-on-accent-1-primary;
              }
            }
            &:hover {
              .actions {
                &:after {
                  @include signPost(violet);
                  right: -1px;
                  width: 2.75rem;
                  height: 2.75rem;
                  background-size: 2.75rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

// Form Stying to Override HubSpot basic CSS
.forms {
  &.hubspot-form {
    // .newsletter .text {
    // 	margin-bottom: 0.75rem;
    // }
    .hs-form-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      form {
        width: 100%;
        height: auto;
        margin: 0 auto;
        select,
        textarea,
        fieldset {
          width: 100%;
          height: auto;
          max-width: unset;
        }
        select {
          color: $ts-background-on-primary-1;
          // font-family: $font-copy;
          font-size: 1rem;
        }
        label:has(> span:empty) {
          display: none;
        }
        ul.hs-error-msgs {
          list-style-type: none;
          margin: 0;
          padding: 0;
          .hs-error-msg {
            color: $ts-alert-error;
            padding: 0.5rem 0;
            width: calc(100% - 1.5rem);
            line-height: 1;
            font-size: 0.75rem;
            font-weight: 500;
            margin-bottom: 0;
            @media screen and (min-width: 481px) and (max-width: 768px) {
              width: calc(100% - 21px);
              margin: 0 auto;
            }
            @media screen and (max-width: 480px) {
              width: 100%;
            }
          }
        }
        .hs-form-field {
          margin: 1rem 0;
          .hs-field-desc {
            @include label(small);
          }
          .input select,
          .input,
          input.hs-input {
            margin-right: 1.5rem;
            @media screen and (max-width: 480px) {
              width: 100%;
              margin: 0 auto;
            }
            &.invalid.error {
              box-shadow: inset 0px 0px 0px 1px $ts-alert-error-outline;
              border-color: $ts-alert-error-outline;
            }
          }
          input[type='radio'],
          input[type='checkbox'] {
            height: 100%;
            margin: 0 0.5rem 0 0;
          }

          &.hs-fieldtype-booleancheckbox {
            .hs-form-booleancheckbox-display {
              display: inline-flex;
              align-items: center;
            }
          }
          &.hs-fieldtype-radio,
          &.hs-fieldtype-checkbox {
            .hs-form-checkbox,
            .hs-form-radio {
              label {
                @include label(medium);
                display: flex;
                align-items: center;
                width: 100%;
                text-align: left;
                margin-left: auto;
                margin-bottom: 0.75rem;
                input {
                  width: 1rem;
                  height: 1rem;
                  margin: 0 0.5rem 0 0;
                }
                span {
                  display: flex;
                  @include label(medium);
                }
              }
            }
          }
          &.hs-fieldtype-file {
            label {
              cursor: pointer;
              width: unset;
              height: unset;
              background-color: $ts-background-primary;
              border: 0.1875rem solid transparent;
              border-radius: 2.5rem;
              color: $black;
              letter-spacing: 0em;
              line-height: 1;
              padding: 0.625rem 1.25rem;
              z-index: auto;
              outline: 0.0625rem solid $ts-action-outline-primary;
              @include mobile {
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.25;
                letter-spacing: -0.02em;
                padding: 0.625rem 1rem;
              }
              &:focus,
              &:active,
              &:hover {
                outline: 0.0625rem solid $ts-outline-on-primary-border-hover;
                border: 0.1875rem solid $ts-outline-on-primary-border-hover;
              }
            }
            input[type='file'] {
              display: none;
            }
          }
        }
        ul.inputs-list {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }
        input:not([type='submit']) {
          display: block;
          width: 100%;
          height: 3.375rem;
          line-height: 3.375rem;
          border: 1px solid $ts-action-outline-primary;
          border-radius: 0.5rem;
          background-color: $ts-container-on-background-primary;
          color: $ts-action-primary;
          // font-family: $font-copy;
          font-size: 1rem;
          padding: 0 1.25rem;
          outline-color: transparent;
          transition: $form-transition;
          box-shadow: none;
          outline: none;
          @include placeholder-text($ts-container-on-container-primary-2);
          &:focus {
            border: 0.125rem solid $ts-accent-1-primary;
            box-shadow: inset 0px 0px 0px 1px $ts-accent-1-primary;
          }
        }
        .legal-consent-container {
          .hs-form-booleancheckbox {
            .hs-form-booleancheckbox-display {
              input {
                margin: 0.25rem 0 0;
                align-self: flex-start;
                width: auto;
              }
              span,
              em,
              p {
                @include label(small);
              }
              a {
                @include label(small-underline);
                color: $mpg-color-blue-100;
              }
            }
          }
        }
        .hs-submit {
          position: relative;
          display: inline-block;
          background: $ts-action-primary;
          border-radius: 2.5rem;
          z-index: 0;
          .actions {
            position: relative;
            display: inline-block;

            &:after {
              content: '';
              @include signPost(white);
              position: absolute;
              top: 50%;
              right: 0.25rem;
              transform: translateY(-50%);
              z-index: 0;
            }
            input[type='submit'] {
              position: relative;
              cursor: pointer;
              width: unset;
              height: unset;
              border: 0;
              background-color: transparent;
              padding: 0;
              margin: 0;
              line-height: unset;
              border-radius: unset;
              font-size: 1.25rem;
              background: transparent;
              border-radius: 2.5rem;
              padding: 0.5rem 3.25rem 0.5rem 1rem;
              color: $ts-action-on-action-primary;
              $btn-transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
              z-index: 1;
            }
          }
          &:hover {
            .actions {
              &:after {
                right: -1px;
                width: 2.75rem;
                height: 2.75rem;
                background-size: 2.75rem;
              }
            }
          }
        }
        &.newsletter {
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
          .hs-form-field:first-child {
            display: inline-block;
            width: 100%;
            margin: 1rem 0;
            .input {
              margin-right: 0;
            }
            label {
              margin-bottom: 0;
            }
          }
          .hs-email.hs-fieldtype-text {
            width: 100%;
            margin-top: 0;
            label {
              display: none;
            }
            .input {
              margin: 0 auto;
              width: 100%;
            }
          }
          .hs-country.hs-fieldtype-select {
            width: 100%;
            label {
              display: none;
            }
            .input {
              margin: 0 auto;
              width: 100%;
            }
          }
          .legal-consent-container {
            display: inline-block;
            ul.inputs-list li {
              label {
                width: 100%;
                input.hs-input {
                  margin: unset;
                  margin-right: 0.5em;
                  height: 18px;
                  width: 18px;
                  left: 0px;
                  top: 3px;
                  padding: 0;
                }
              }
              p {
                text-transform: unset;
                font-weight: 400;
                font-size: 0.8125rem;
                color: $ts-background-on-primary-2;
                position: relative;
                top: -6px;
                letter-spacing: unset;
                transition: 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
                line-height: 1;
                a {
                  text-decoration: none;
                  letter-spacing: unset;
                  color: $color-blue-100 !important;
                  text-transform: none;
                  font-weight: 500;
                  font-size: 0.8125rem;
                  &:hover {
                    color: $color-blue-75 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}