@import '~slick-carousel/slick/slick.css';
@import 'slick-theme.scss';
@import './../../../scss/mixins';

.carousel {
  padding: 3.75rem 0;
  @include respond-between(md, xl) {
    padding: 5rem;
  }
  @include respond-above(xl) {
    padding: 5rem 0;
  }
  .big_card {
    .slick-track {
      .slick-slide {
        width: 100%;
        max-width: 1270px !important;
        height: inherit !important;
        @include respond-below(sm) {
          display: flex;
          gap: 1rem;
          flex-direction: column;
          height: 670px !important;
        }
      }
    }
  }
  .header-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 3.75rem;
    @include respond-above(lg) {
      flex-direction: row;
    }
    .cta-wrapper {
      display: flex;
      justify-content: flex-start;
      margin: 1.5rem 0 0;
      width: 100%;
      @include respond-above(lg) {
        justify-content: flex-end;
        margin: 0;
      }
    }
  }
  .slick-slider {
    .slick-track {
      display: flex !important;
      gap: 1.25rem;
      margin-bottom: 2rem;
      @include respond-below(sm) {
        gap: 1rem;
        margin-bottom: 1.5rem;
      }
      .slick-slide {
        height: inherit !important;
        > div {
          height: 100%;
        }
      }
    }
    .slick-arrow {
      &.slick-prev {
        top: unset;
        bottom: -1.5rem;
        right: 5.125rem;
        left: auto;
        @include mobile {
          right:4.8rem;
        }
        &:before {
          display: none;
        }
        &:after {
          display: block;
          content: '';
          background-image: url('../../../static/images/icon--pagination-next-active.svg');
          background-size: 2.5rem;
          background-repeat: no-repeat;
          width: 2.5rem;
          height: 2.5rem;
          // border: 1px solid $ts-action-on-disabled-primary;
          // border-radius: 50%;
          transform: rotate(-180deg);
          @include mobile {
            background-size: 2rem;
            width: 2rem;
            height: 2rem;
          }
        }
        &.slick-disabled {
          &:after {
            border-color: $ts-action-disabled-primary;
          }
        }
      }
      &.slick-next {
        top: unset;
        bottom: -1.5rem;
        right: 2.5rem;
        &:before {
          display: none;
        }
        &:after {
          display: block;
          content: '';
          background-image: url('../../../static/images/icon--pagination-next.svg');
          background-size: 2.5rem;
          background-repeat: no-repeat;
          width: 2.5rem;
          height: 2.5rem;
          @include mobile {
            background-size: 2rem;
            width: 2rem;
            height: 2rem;
          }
        }
      }
    }
    .slick-dots {
      position: absolute;
      bottom: 1rem;
      margin-left: auto;
      display: block;
      width: auto;
      right: 8rem;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      li {
        display: none;
        color: $ts-color-gray-75;
        &.slick-active {
          display: inline-block;
          color: $black;
          margin-right: 20px;
        }
        .pagination {
          display: flex;
          color: $ts-color-gray-75;
          .active {
            color: $black !important;
          }
        }
      }
    }
  }
  &.events {
    padding: 2.5rem 0 0;
    margin: 0;
    .header-wrapper {
      margin-bottom: 1.25rem;
    }
    .title {
      @include headline(medium);
      margin-bottom: 0;
      @include mobile {
        max-width: 85%;
      }
    }
    .carousel-wrapper {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding: 0;
    }
    .slick-slider {
      .slick-track {
        gap: 1.25rem;
      }
      .slick-arrow {
        &.slick-prev {
          bottom: unset;
          top: -2.5rem;
          right: 5.25rem;
        }
        &.slick-next {
          bottom: unset;
          top: -2.5rem;
          right: 2.25rem;
        }
      }
      .slick-dots {
        right: 10rem;
        @include respond-above(sm) {
          top: -2.5rem;
          bottom: unset;
        }
        @include respond-below(sm) {
          right: 50%;
          bottom: -15px;
        }
      }
    }
  }
  &.insights {
    @include respond-between(md, xl) {
      padding: 5rem 0 3rem 4rem;
    }
    @include respond-above(xl) {
      padding: 5rem 0 4rem 4rem;
    }
    &.primary {
      background-color: #efefef;
      @include respond-below(md) {
        background-color: #efefef;
      }
      background-color: $ts-background-primary;
      .carousel .slick-slider .slick-dots li .pagination .active {
        color: $black;
      }
    }
    &.secondary {
      background-color: rgba(255, 255, 255, 0.05);
      color: $white;
      .carousel .slick-slider .slick-dots li .pagination .active {
        color: $white;
      }
      .card {
        @include frostedGlass(extra-light);
        // box-shadow: 0px 7.40924px 11.5868px rgba(198, 198, 197, 0.175929),
        //   0px 4.25707px 6.65734px rgba(198, 198, 197, 0.20529),
        //   0px 1.87365px 2.93007px rgba(198, 198, 197, 0.27) !important;
        box-shadow: none; //do not change

        .card-body {
          background-color: transparent;
        }
      }
      .slick-dots {
        li {
          color: $ts-color-gray-75;
          &.slick-active {
            color: $white;
          }
          .pagination {
            color: $ts-color-gray-75;
            .active {
              color: $white;
            }
          }
        }
      }
    }
    .carousel-wrapper {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      @include respond-between(md, xl) {
        padding-right: 0;
      }
      @include respond-below(sm) {
        padding: 0;
      }
    }
    .slick-track {
      .slick-slide {
        width: 50%;
        max-width: 310px;
        height: inherit !important;
        @include respond-below(sm) {
          display: flex;
          gap: 1rem;
          flex-direction: column;
          height: inherit;
        }
        &:first-child {
          width: 100%;
          @include respond-above(xl) {
            max-width: 645px;
          }
          @include respond-above(md) {
            width: 300px !important;
          }
        }
        &:first-child > div:nth-child(2) {
          display: none;
        }
        &:not(:first-child) {
          > div {
            height: 100%;
            @include respond-above(sm) {
              height: 100%;
            }
          }
        }
      }
    }
    .section-header {
      .title {
        @include headline(6);
        font-size: 2.5rem;
        margin-bottom: 2.5rem;
        @include respond-below(md) {
          font-size: 1.5rem;
        }
      }
    }

    .card {
      height: 100%;
      margin: 0;
      .card-body {
        // padding: 32px 24px 24px 24px;
        // width: 24.188rem;
      }
      .card-body-corousel {
        // padding: 1.5rem;
      }

      &__full {
        @include respond-above(sm) {
          flex-direction: row;
        }
      }
    }
    &.component {
      @include respond-below(sm) {
        .slick-arrow {
          &.slick-prev {
            top: unset;
            bottom: -1.5rem;
            right: 4.68rem;
          }
          &.slick-next {
            top: unset;
            bottom: -1.5rem;
            right: 2.25rem;
          }
        }
      }
    }
    &.sub-component {
      padding: 3rem 0 0;
      margin: 0;
      @include respond-between(md, xl) {
        padding: 3rem 0 0 4rem;
        .carousel-wrapper {
          padding-right: 0;
        }
      }
      @include respond-below(sm) {
        .carousel-wrapper {
          padding: 0;
        }
      }
      .section-header {
        .title {
          @include headline(5);
          @include respond-below(sm) {
            max-width: 85%;
          }
        }
      }
      .carousel-wrapper {
        .card {
          &__insights {
            &.featured.secondary {
              .card-content {
                .title {
                  @include headline(small);
                }
              }
            }
          }
          .button.tag.dark.outline {
            background-color: transparent;
          }
        }
      }
      .slick-arrow {
        &.slick-prev {
          bottom: unset;
          top: -3.5rem;
        }
        &.slick-next {
          bottom: unset;
          top: -3.5rem;
        }
      }
      .slick-dots {
        @include respond-above(sm) {
          top: -3.5rem;
          bottom: unset;
        }
        @include respond-below(sm) {
          right: 50%;
        }
      }
    }
    .slick-arrow {
      &.slick-prev {
        bottom: unset;
        top: -3.5rem;
        @include respond-between(sm, xl) {
          right: 9.25rem;
        }
        @include respond-below(md){
          top:-2.5rem
        }
      }
      &.slick-next {
        bottom: unset;
        top: -3.5rem;
        @include respond-between(sm, xl) {
          right: 6.25rem;
        }
        @include respond-below(md){
          top:-2.5rem
        }
      }
    }
    .slick-dots {
      top: -3.5rem;
      bottom: unset;
      @include respond-between(sm, xl) {
        right: 13rem;
      }
      @include respond-below(sm) {
        display: none !important;
      }
    }
  }
  &.outcomes {
    padding: 3.75rem 0;
    @include respond-between(md, xl) {
      padding: 3.75rem 5rem;
    }
    @include respond-below(lg) {
      // padding-right: 0;
    }
    @include respond-below(md) {
      padding: 3.75rem 1.25rem !important;
    }
    .header-wrapper {
      margin-bottom: 1.75rem;
      .title {
        padding-bottom: 16px;
        @include headline(medium);
        margin: 0;
        @include respond-above(md) {
          @include headline(4);
        }
      }
      .header-class{
        flex: 1;
      }
      .sub-title{
        margin: 0;
        padding-bottom: 28px;
      }
    }
    .carousel-wrapper {
      padding: 0;
      position: relative;

      .slick-list {
        bottom: 40px;
        @include respond-below(md) {
          bottom: 0px;
        }
      }
      .slick-arrow {
        &.slick-prev {
          top: unset;
          bottom: 1rem;
        }
        &.slick-next {
          top: unset;
          bottom: 1rem;
          &:after {
            background-image: url('../../../static/images/icon--pagination-next-active.svg');
          }
        }
      }
      .slick-dots {
        right: 8rem;
      }
      // @include respond-between(sm, lg) {
      @include respond-above(md) {
        .slick-arrow {
          &.slick-prev {
            right: 7.25rem;
            bottom: 15px;
          }
          &.slick-next {
            right: 4.25rem;
            bottom: 15px;
          }
        }
        .slick-dots {
          right: 10rem;
          bottom: 15px;
        }
      }
      @include respond-between(sm, md) {
        .slick-arrow {
          &.slick-prev {
            right: 7.25rem;
            bottom: -15px;
          }
          &.slick-next {
            right: 4.25rem;
            bottom: -15px;
          }
        }
        .slick-dots {
          right: 10rem;
          bottom: -20px;
        }
      }
      @include respond-below(sm) {
        .slick-arrow {
          &.slick-prev {
            right: 4.5rem;
            bottom: -40px;
          }
          &.slick-next {
            right: 2.25rem;
            bottom: -40px;
          }
        }
        .slick-dots {
          right: 7rem;
          bottom: -2.5rem;
        }
      }
    }
    &__1 {
      .card {
        .card-image,
        .card-body {
          flex-basis: 50%;
          max-width: 220px;
          .title {
            @include respond-above(md) {
              margin-top: auto;
            }
          }
        }
      }
    }
    &__2 {
      .card {
        .card-image {
          flex-basis: 33.33%;
        }
        .card-body {
          flex-basis: 66.66%;
        }
      }
    }
  }
  &__quote {
    .header-wrapper {
      margin-bottom: 2.5rem;
      align-items: center;
      @include respond-above(sm) {
        margin-bottom: 3.75rem;
      }
      .title {
        margin-bottom: 0;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 110% */
        letter-spacing: -0.5px;
      }
      .sub-title{
        margin-bottom: unset;
      }
      .cta-wrapper {
        justify-content: flex-end;
      }
    }
    .carousel-wrapper {
      padding: 0;
    }
    .slick-slider {
      // .slick-list {
      //     padding: 1rem 0 0;
      // }
      .slick-track {
        display: flex !important;
        .slick-slide {
          height: auto;
          > div {
            height: 100%;
            a.card {
              pointer-events: none;
            }
          }
        }
      }
      @include respond-between(sm, md) {
        .slick-arrow {
          &.slick-prev {
            right: 9.25rem;
          }
          &.slick-next {
            right: 6.25rem;
          }
        }
        .slick-dots {
          right: 13rem;
        }
      }
    }
  }
}

// for disabling arrows on first and last slide
.slick-arrow.slick-prev.slick-disabled,
.slick-arrow.slick-next.slick-disabled {
  opacity: 0.5;
}

.card-footer-carousel {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 0;
}
.carousel-wrapper {
  .progress {
    background-color: #efefef;
    height: 2px;
    position: absolute;

    @include respond-above(xl) {
      width: 70%;
      bottom: 25px;
      left: 20px;
    }

    @include respond-between(md, xl) {
      width: 70%;
      bottom: 20px;
      left: 20px;
    }
    @include respond-below(md) {
      width: 300px;
      bottom: -30px;
      left: 20px;
    }
    @include respond-between(sm, md) {
      width: 500px;
      bottom: -10px;
      left: 20px;
    }
    @include respond-between(xs, sm) {
      width: 300px;
      bottom: -30px;
      left: 20px;
    }
    @include respond-below(xs) {
      width: 150px;
      bottom: -30px;
      left: 20px;
    }
    .progress-bar {
      width: 20%;
      background-color: #a6eb8c;
    }
  }
}
@include respond-below(md) {
  .card-body-image-carousel img {
    width: 310px !important;
    height: 190px !important;
  }
  .primary .card__full.slide .card-body {
    height: 15.25rem !important;
  }
}
.card-body.related-insights-carousal {
  .cttagcontainer {
    margin: 0rem;
    width: 21.188rem;
    padding: 0rem;
  }
  @include respond-below(md) {
    .cttagcontainer {
      margin: 0rem;
      width: 15.25rem;
      padding: 0rem 0rem 1.5rem 0rem;
    }
    .related-solutions {
      width: 15.25rem !important;
    }
    .card-content-carousel {
      width: 15.25rem !important;

      .sub-title-related-insights-carousal,
      .title {
        margin-bottom: 0.5rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .sub-title-related-insights-carousal {
        font-family: Inter;
        font-size: 0.75rem;
        font-weight: 400;
        color: var(--tokens-container-on-container-primary-1, $color-lightgray-100);
      }
      .mobtitle {
        font-family: Inter;
        font-size: 1.25rem;
        font-weight: 400;
        color: var(--tokens-container-on-container-primary-2, $black);
        margin: 0rem 0rem 0.75rem 0rem;
      }
      .para .rich-text-alert {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  .related-solutions {
    margin: 0rem;
    width: 21.188rem;
  }
  .card-content-carousel {
    margin: 0rem;
    width: 21.188rem;
    .sub-title-related-insights-carousal,
    .title {
      margin-bottom: 0.5rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .sub-title-related-insights-carousal {
      font-family: Inter;
      font-size: 0.75rem;
      font-weight: 400;
      color: var(--tokens-container-on-container-primary-1, $color-lightgray-100);
    }
    .title {
      font-family: Inter;
      font-size: 1.25rem;
      font-weight: 400;
      color: var(--tokens-container-on-container-primary-2, $black);
    }
    .para .rich-text-alert {
    max-height: 4.6em; 
    line-height: 1.8em;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}
.carousel.insights .section-header .title {
  &.on-primary {
    // color: #000;
  }
}
.card-body-image-carousel {
  .body-card-button {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
.banner-section{
  .carousel.insights {
    padding: 5rem 0 5rem 5rem; //do not change

    @include respond-below(md) {
      padding: 3.75rem 0 3.75rem 1.25rem !important; //do not change
      background-color: $white;
    }

    &.secondary {
      background-color: $black;
      color: $white;

      a.card.card__full {
        background: #0e0e0e;
        box-shadow: none !important;

        .card-body.related-insights-carousal {
          .card-content-carousel {
            height: auto;
            background-color: initial;
            .para {
              .rich-text-alert > p {
                color: $white;
              }
            }
          }
          .related-solutions.related-insights {
            display: flex;
            flex-wrap: wrap;
            gap: 0.75rem;
            margin: auto 0 0 0;
          }
        }
      }

      h2.title {
        color: #fff;
      }

      .carousel-wrapper.related-insights .slick-slider .slick-dots li .pagination .active {
        color: #fff !important;
      }

      .card .card-body.related-insights-carousal {
        backdrop-filter: blur(10px);
        background: rgba(255, 255, 255, 0.05);

        .cttagcontainer {
        //  margin-bottom: 12px;
          color: #fff;
        }
        .card-content-carousel {
          h3.title {
            color: #fff !important;
          }
        }
        .related-solutions.related-insights {
          button {
            background-color: #282a32;
            color: #ffffff;
          }
        }
      }

      .card.card__insights.secondary {
        box-shadow: none !important;

        .card-body {
          background-color: #0e0e0e;
          padding: 32px 24px 24px 24px;

          .card-details .content-type {
            color: #fff !important;
          }

          .card-content {
            .title {
              color: #fff !important;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;
              letter-spacing: -0.5px;
            }
          }

          .card-footer-carousel {
            div {
              button.text-button {
                color: #fff !important;
                &.tag.article {
                  background-color: #282a32;
                }
              }
            }
          }
        }
      }
    }
  }
}

.carousel .card__insights.primary {
  background-color: $white;
  .card-body {
    &.card-body .card-body-carousel.related-insights-carousal {
      width: 100% !important;
      height: auto !important;
    }
    .card-details .content-type {
      color: #000 !important;
    }
    .card-content {
      .title {
        color: #000 !important;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.5px;
      }
    }
    .card-footer-carousel {
      div {
        .button.text-button {
          color: #000 !important;
          border-bottom: solid 2px #000000;
          &.tag.article {
            background-color: #efefef;
          }
        }
      }
    }
  }
}
.banner-section .carousel.insights .card .card-body.related-insights-carousal .cttagcontainer {
  // margin-bottom: 20px;
  @include respond-below(md) {
    margin-bottom: 1.5rem;
  }
}
.banner-section
  .carousel.insights
  .card
  .card-body.related-insights-carousal
  .card-content-carousel
  h3.title {
  margin: 12px 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: -0.1px;

  @include respond-below(md) {
    margin: 0;
    margin-bottom: 0.75rem;
  }
}
.related-solutions.related-insights {
  margin-top: 24px;
}
.banner-section
  .carousel.insights
  .card
  .card-body.related-insights-carousal
  .card-content-carousel
  .para
  div
  p {
  color: #000; // do not change
}
.banner-section
  .carousel.insights
  .card
  .card-body.related-insights-carousal
  .card-content-carousel
  .para
  .rich-text-alert {
  max-height: 85px; // do not change
  overflow: hidden;
}
.carousel.insights.secondary {
  .carousel-wrapper.related-insights {
    .card.card__insights {
      .card-body {
        .card-footer-carousel {
          div {
            .button.text-button {
              color: $white; //do not change
              border-bottom: solid 2px #fff;
            }
          }
        }
      }
    }
  }
}
.carousel.insights.secondary .card__insights .card-body .card-details .content-type {
  color: $white !important; //do not change
}
.carousel.insights .card.card__insights .card-body {
  @include respond-above(md) {
    padding: 36px 32px 32px 32px; //do not change
  }
}
.carousel.insights.primary .card__insights .card-body .card-details .content-type {
  color: $black; //do not change
}
.carousel.insights .slick-track .slick-slide{
  @include respond-above(md) {
    min-width: 625px;
  }
}
.listenbtn {
  min-width: 2.75rem;
  height: 24px !important;
  min-height: 24px !important;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  border-bottom: 0.125rem solid black !important;
}
.carousel.carousel__quote .slick-slider .slick-dots {
  bottom: -25px;
}
.carousel.carousel__quote .slick-slider .slick-arrow.slick-next {
  right: 0.5rem;
}
