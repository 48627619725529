@import '../../../scss/mixins';
@import '../../../scss/colors';
@import '../../../scss/variables';

.subnav-section {
  padding: 3.75rem 1.25rem;
  @include mobile{
    padding: 3.75rem 1.25rem;
  }
  @include respond-between(md, xl) {
    padding: 5rem;
  }
  &.no-padding {
    padding: 0 !important;
  }
  .section-header {
    padding: 0 0 2.5rem;
    .grid-title {
      h1 {
        font-weight: 400;
        line-height: 44px;
      }
      h2{
        font-size: 2rem;
        line-height: 112.5%;
        margin-bottom: 0;
      }
    }
  }
  .accordion-section {
    margin: 0;
    padding: 0;
    .accordion-block {
      border: 0;
      margin: 0 0 1rem 0;
      padding: 1.25rem;
      border-radius: $ts-card-border-radius;
      box-shadow: $ts-card-box-shadow-light;
      transition: $btn-transition;
      &:first-child {
        border-top: 0;
      }
      &:hover {
        box-shadow: $ts-card-box-shadow-light-hover;
      }
      .accordion-title {
        @include body-copy(medium);
        font-family: $font-family-2;
        position: relative;
        display: flex;
        align-items: center;
        img {
          margin-right: 1rem;
        }
        @media screen and (max-width: 768px) {
          img {
            width: 32px;
          }
        }
      }
      .icon {
        background-image: url('../../../static/icons/icon--plus.svg');
        background-repeat: no-repeat;
        background-size: 1rem;
        width: 1rem;
        height: 1rem;
        margin: 0;
        position: absolute;
        right: 0;
        top: 25%;
        &:after {
          content: none;
        }
      }
      &.active {
        .icon {
          background-image: url('../../../static/icons/icon--minus.svg');
        }
      }
      .accordion-body {
        margin: 2.5rem 0.5rem 1.5rem;
        .title {
          margin-bottom: 0.5rem;
        }
        .sub-title {
          margin: 0;
        }
        .cta__wrap {
          margin: 1.25rem 0 0 0;
        }
        .related-solution {
          display: none;
        }
        .links {
          display: flex;
          flex-direction: column;
          .button {
            padding: 0.75rem 0;
            margin: 0;
            .arrow-icon {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}

.tabs {
  filter: drop-shadow(3px 5px 10px rgba(147, 148, 152, 0.25));
  .nav {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    border-bottom: none;

    @include respond-above(md) {
      flex-direction: row;
    }
    .nav-item {
      display: flex;
      flex-grow: 1;
      text-align: center;
      list-style-type: none;
      margin-right: 1rem;
      border-radius: $ts-card-border-radius;
      flex-basis: calc(25% - 1rem);
      cursor: pointer;
      z-index: 3;
      &.active {
        background-color: $ts-background-primary;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .nav-link {
        @include body-copy(medium);
        font-family: $font-heading;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
        margin-bottom: 1rem;
        border-radius: $ts-card-border-radius;
        background-color: $ts-background-primary;
        color: $ts-background-on-primary-1;
        width: 100%;
        text-decoration: none;
        text-align: left;
        padding: 0.75rem;
        position: relative;
        z-index: 2;
        &::after {
          content: '+';
          height: 2rem;
          width: 2rem;
          font-size: 2rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          color: $ts-background-secondary;
          margin-left: auto;
        }
        &.active {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          box-shadow: none;
          border-color: white;
          &::after {
            content: '—';
            font-size: 1.25rem;
            font-weight: 700;
          }
        }
        .icon {
          height: 2rem;
          width: 2rem;
          margin-right: 0.75rem;
        }
      }
    }
  }

  .tab-content {
    position: relative;
    .tab-pane {
      display: none;
      background-color: $ts-background-primary;
      border-radius: $ts-card-border-radius;
      padding: 3.75rem;
      &:first-child {
        border-top-left-radius: 0;
      }
      &:last-child {
        border-top-right-radius: 0;
      }
      &.active {
        display: block;
        .flex-container {
          .flex-container1 {
            width: 35%;
            border-right: 1px solid $color-lightgray;
            padding-right: 60px;
          }

          .flex-container2 {
            width: 65%;
            padding-left: 60px;
          }
          display: flex;
          flex-direction: row;
        }
      }
      .title {
        @include headline(5);
        margin-bottom: 0.75rem;
        &.grid-title {
          line-height: 34px;
        }
      }
      .sub-title {
        color: $ts-background-on-primary-2;
        font-family: $font-family-2;
      }
      .cta__wrap {
        margin-top: 2rem;
        .button.small {
          border-bottom: 1px solid $color-lightgray;
        }
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        .label {
          margin-bottom: 3rem;
        }
        .links {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 0 2rem;
          align-items: flex-end;
          height: fit-content;

          // margin-top: auto;
          .button {
            margin: 0;
            padding: 0.75rem 0px;
            text-align: start;
            &:hover {
              &:before {
                height: 0.0625rem;

                bottom: -0.0625rem;
              }
            }
            .arrow-icon {
              margin-left: auto;
            }
          }
          .button.text-button:before {
            background-color: $color-lightgray !important;
          }
        }
      }
    }
    .fade-in {
      animation: fadeIn 0.3s;
    }
  }
}

@keyframes fadeIn {
  from {
    visibility: hidden;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
.primary {
  background-color: $white;
  color: $black;
}
.secondary {
  background-color: $black;
  color: $white;

  .tabs .nav .nav-item {
    margin-bottom: 1rem;
    box-shadow: $ts-card-box-shadow-light;
    &.active {
      background-color: rgba(255, 255, 255, 0.05) !important;
      box-shadow: none;
      margin-bottom: 0;
    }
    .nav-link {
      background-color: rgba(255, 255, 255, 0.05);
      color: $white;
      margin-bottom: 0;
      border: unset;
      &::after {
        color: $white;
      }
    }
  }
  .tabs .tab-content .tab-pane {
    background-color: rgba(255, 255, 255, 0.05);
    color: $white;
    .sub-title {
      color: $white;
    }
    .wrapper {
      .links {
        .button {
          &:hover {
            &:before {
              background-color: $ts-background-primary;
            }
          }
        }
      }
    }
  }
  .accordion-section {
    background-color: rgba(255, 255, 255, 0.05);

    .accordion-body button.white {
      border-bottom: 1px solid $white;
    }
    .accordion-block {
      &:hover {
        box-shadow: $ts-card-box-shadow-light;
      }
      .icon {
        background-image: url('../../../static/icons/icon--plus-white.svg');
      }
      &.active {
        .icon {
          background-image: url('../../../static/icons/icon--minus--white.svg');
        }
      }
    }
  }
}
