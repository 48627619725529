@import './../../../scss/colors';
@import './../../../scss/mixins';
@import '../../../globals/typography/typography.scss';

.calloutbar-section {
  position: relative;
  &.alert {
    padding: 2rem 1.25rem;
    border-radius: revert;
    margin-bottom: 0;
    @include respond-above(md) {
      padding: 1.25rem 5rem;
    }
    .close-button {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      transition: $btn-transition;
      @include respond-below(lg) {
        right: 0;
        top: 0;
      }
      &:hover {
        opacity: 0.75;
      }
      img {
        width: 1rem;
        height: 1rem;
      }
    }
    .content-wrapper {
      align-items: center;
      flex-direction: column;
      @include respond-above(md) {
        flex-direction: row;
      }
      .rich-text {
        flex: 1;
        @include respond-above(md) {
          @include headline(small);
        }
      }
      .rich-text-alert h3 {
        flex: 1;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        @include respond-above(md) {
          @include headline(small);
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 1.5rem; /* 120% */
          letter-spacing: -0.1px;
        }
      }

      .cta__wrap {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 1rem 0 0;
        min-width: max-content;
        flex: 0;
        @include respond-above(md) {
          justify-content: flex-end;
          flex-direction: row;
          margin: 0;
        }
        @include respond-below(lg) {
          gap: 1.25rem;
        }

        .button {
          font-family: $font-inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.32px;

          &:first-child {
            // margin: 0 0 1rem 0;
            margin-right: 1.25rem;
            @include respond-above(md) {
              margin-right: 1.25rem;
            }
          }
        }
      }
    }
    &.primary {
      color: $ts-background-on-primary-1;
      background-color: $ts-background-primary;
    }
    &.secondary {
      color: $ts-background-on-secondary-1;
      background-color: $ts-background-secondary;
    }
    &.violet {
      color: $ts-accent-1-on-accent-1-primary;
      background-color: $ts-accent-1-primary;
    }
    &.green {
      color: $ts-accent-2-on-accent-2-primary;
      background-color: $ts-accent-2-primary;
    }
    &.blue {
      color: $ts-accent-3-on-accent-3-primary;
      background-color: $ts-accent-3-primary;
    }
    &.primary,
    &.green,
    &.blue {
      .close-button {
        img {
          filter: contrast(0) brightness(0);
        }
      }
    }
    &.closed {
      display: none;
    }
  }
  &.contact {
    background: $ts-background-primary;
    @include respond-above(lg) {
      padding: 5rem;
    }
    @include respond-below(xl) {
      // padding: 2.5rem 1.25rem;
    }
    .content-wrapper {
      align-items: center;
      .title {
        @include headline(3);
      }
      .title__contact {
        font-weight: 400;
        font-size: 2.25rem;
        line-height: 116.6%;
        letter-spacing: -1px;
        @include mobile {
          font-size: 1.75rem;
          line-height: 34px;
          letter-spacing: -0.5px;
        }
      }
      p {
        color: $ts-background-on-primary-2;
        margin-bottom: 2rem;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
      }

      .contact-details {
        .phone_contact,
        .email_contact {
          display: flex;

          @include label(medium);
          @include respond-above(md) {
            flex-direction: column;
            @include headline(small-bold);
          }
          span {
            @include label(medium);
            font-weight: 600;
            color: $ts-background-on-primary-2;
            @include respond-above(md) {
              @include headline(small-bold);
            }
            @include respond-above(md) {
              &::after {
                content: '\a';
                white-space: pre;
              }
            }
          }

          a {
            @include label(medium);
            color: $ts-background-on-primary-2;
            text-decoration: none;
            @include respond-above(md) {
              @include headline(small);
            }
          }
        }
      }
      .divider {
        border-bottom: 0.0625rem solid $color-lightgray;
        margin: 0 0 2.5rem 0;
        padding: 0 0 2.5rem 0;
        @include respond-above(md) {
          border: 0;
          border-right: 0.0625rem solid $color-lightgray;
          margin: 0 5rem 0 0;
          padding: 0 5rem 0 0;
        }
      }
      .spacer {
        padding-bottom: 2rem;
        @include respond-below(lg) {
          padding-bottom: 1.5rem;
        }
      }
      .social-wrapper {
        display: flex;
        flex-direction: column;
        .social-title-contact {
          font-weight: 700;
          letter-spacing: -0.1px;
          font-size: 1.25rem;
          line-height: 100%;
          letter-spacing: -0.1px;
          margin: 0 0 1.25rem 0;
          @include mobile {
            font-size: 1rem;
            line-height: 125%;
            letter-spacing: -0.5px;
          }
        }
        .social-follow {
          display: flex;
          .social-item {
            margin: 0 1.5rem 0 0;
            &:hover {
              opacity: 0.75;
            }
            img {
              width: 2rem;
              height: 2rem;
              @include respond-above(lg) {
                width: 2.25rem;
                height: 2.25rem;
              }
            }
          }
        }
      }
    }
  }
  &.footer {
    background: $ts-background-primary;
    // margin: 5rem;
    padding: 4rem 2.5rem;
    @include respond-below(md) {
      // margin: 2.5rem 1.25rem;
      padding: 2.75rem 1.375rem 1.375rem 1.375rem;
    }
    @include respond-above(xl) {
      padding: 5rem;
    }
    &.dual {
      .card-wrapper {
        display: flex;
        flex-direction: column;
        @include respond-above(md) {
          flex-direction: row;
        }
      }
      .card {
        @include respond-above(md) {
          width: 50%;
        }
        &:first-child {
          margin: 0 0 1.25rem 0;
          padding: 40px 28px;
          @include respond-above(md) {
            margin: 0 1.25rem 0 0;
            padding: 64px 40px;
          }
        }
        .card-body {
          flex-direction: column;
          gap: 1.25rem;
          .title {
            @include headline(4);
            width: 100%;
            margin-bottom: 0;
          }

          .cta__wrap {
            width: 100%;
            margin: auto 0 0 0;
            button {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

h2.title.content-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.375rem;
  letter-spacing: -0.03125rem;
}
.social-title-contact {
  @include respond-above(md) {
    margin: 0 0 0 0;
  }
  color: #000;
}
.contact-details {
  .email_contact {
    margin-bottom: 2rem !important;
  }
  .phone_contact {
    @include respond-above(md) {
      padding-bottom: 1.25rem;
    }
    @include respond-below(md) {
      padding-bottom: 1rem;
    }
    span {
      font-family: $font-family-1;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 120% */
      letter-spacing: -0.1px;
    }
  }
}
.icon_contact {
  display: flex;
  margin-top: 0px;
  margin-right: 1.25rem;
  width: 2rem;
  height: 2rem;
}
.calloutbar-section.alert {
  .content-wrapper {
    .cta__wrap {
      a:last-child {
        .button {
          margin-right: 0;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .calloutbar-section.footer.dual {
    .card {
      .card-body {
        .title {
          font-family: $font-family-1;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 38px; /* 118.75% */
          letter-spacing: -0.5px;
          margin-bottom: 2rem;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .calloutbar-section.footer.dual {
    .card {
      .card-body {
        gap: 2.5rem;
        .title {
          font-family: $font-family-1;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 118.75% */
          letter-spacing: -0.5px;
        }
      }
    }
  }
}

.form-wrapper-dual {
  padding-top: 2rem;
}
