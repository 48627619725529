@import '../../../scss/colors';
@import '../../../scss/mixins';

.container-xl {
  // padding: 0rem !important;
  max-width: 80rem !important;
}
.filterDropdown {
  position: relative;
  isolation: isolate;
  .button.text-button.small {
    width: 1.75rem !important;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @include respond-below(md) {
    max-width: 100%;
  }

  .filterDropdownTitle {
    position: relative;
    border-radius: 16px;
    padding: 0.5rem;
    height: 44px;
    border-width: 1px;
    min-width: 15.563rem;
    border-style: solid;
    padding-top: 6px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    justify-content: space-between;
    cursor: pointer;
    background-color: $ts-color-white;
    color: $ts-color-black;

    &:after {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      // Black chevron down
      content: '';
      background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.5 1.25L9 8.75L1.5 1.25' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 15px;
      height: 7.5px;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &.dropdown_open {
    z-index: 20;
    .filterDropdownTitle {
      background-color: $ts-color-violet-75;
      color: $ts-color-white;
      padding: 10px 1.25rem;
      &:after {
        // White chevron up
        content: '';
        background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 9.75L9 2.25L16.5 9.75' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A") !important;
      }
    }

    .filter_options__Dropdown {
      display: block;
      background-color: #efefef;
    }
  }

  &.dropdown_closed {
    .filterDropdownTitle {
      border-color: $ts-color-black;
      display: flex;
      width: 15.563rem;
      padding: 0.688rem 1.25rem;
      align-items: center;
      gap: 1.25rem;
      flex-shrink: 0;
      border-color: $ts-color-gray-50;

      &[data-filter-count]:not([data-filter-count='0']) {
        background-color: $ts-color-gray-25;
        border-color: $ts-color-gray-25;
        padding-left: 50px;

        &:before {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);

          content: attr(data-filter-count);
          background-color: $ts-color-violet-75;
          color: $ts-color-white;
          border-radius: 50%;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .filter_options__Dropdown {
      display: none;
    }
  }

  .filter_options__Dropdown {
    background-color: $ts-color-white;
    padding-top: 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 16px;
    z-index: -1;
    // box-shadow: 0px 28px 40px rgba(198, 198, 197, 0.115858),
    //   0px 18px 28px rgba(198, 198, 197, 0.135), 0px 12px 18px rgba(198, 198, 197, 0.154142),
    //   0px 7px 11px rgba(198, 198, 197, 0.175929), 0px 4px 6px rgba(198, 198, 197, 0.20529),
    //   0px 2px 3px rgba(198, 198, 197, 0.27);

    &__top {
      min-height: 70px;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $ts-color-gray-25;

      .count_selected {
        max-width: 49%;
        padding-right: 10px;
        font-size: 12px;
        * {
          font-size: inherit;
        }
      }

      .clear_all {
        width: 6.5rem;
        height: 1.625rem;
        button {
          width: 7rem;
          height: 1.625rem;
          font-size: 0.75rem;
          background-color: transparent;
          padding: 6px 3px;
          min-width: 0px;
          outline: none;
          border: 0.0625em solid $ts-action-outline-primary;

          img {
            width: 0.75rem;
            height: 0.75rem;
          }
          #text {
            width: 4.375rem;
          }
        }
        .button &.icon {
          &.left {
            margin: 0 0 0 0;
          }
        }
      }
    }
  }

  &__bottom {
    padding: 12px 8px;
    background-color: $ts-color-white;
    border-radius: 16px;
  }
}

.dropdown__item {
  &.disabled {
    pointer-events: none;
    opacity: 0.25;
    filter: grayscale(100%);
  }

  &:last-child {
    .form-block.checkbox {
      margin-bottom: 0;
    }
  }

  .form-block {
    &.checkbox {
      border-radius: 8px;
      margin-bottom: 0rem;

      input[type='checkbox'] {
        position: absolute;
      }

      label {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: unset;
        padding: 12px 20px 12px 42px;
        font-family: Arial; //Do not change
        font-weight: 400 !important; //Do not change
        font-style: normal; //Do not change
        &:before {
          top: 13px;
          left: 20px;
          width: 16px;
          height: 16px;
        }
        &:after {
          top: 18px;
          left: 24px;
          width: 9px;
        }
      }

      &:before {
        top: unset;
      }

      &:after,
      img {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        content: '';
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 18px;
        height: 18px;
        pointer-events: none;
      }

      &:hover {
        background: $ts-color-gray-25;

        label {
          &:before {
            border-color: $ts-color-violet-75;
            outline: $ts-color-violet-75 auto 0.25rem;
          }
        }
      }
    }
  }

  &.all-solutions {
    .form-block {
      &.checkbox {
        &:after {
          background-image: url("data:image/svg+xml,%0A%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.873901' y='0.625' width='7.05769' height='7.05769' rx='3.52885' stroke='black' stroke-width='1.25'/%3E%3Crect x='0.873901' y='10.3174' width='7.05769' height='7.05769' rx='3.52885' stroke='black' stroke-width='1.25'/%3E%3Crect x='10.5662' y='0.625' width='7.05769' height='7.05769' rx='3.52885' stroke='black' stroke-width='1.25'/%3E%3Crect x='9.94122' y='9.69238' width='8.30769' height='8.30769' rx='4.15385' fill='black'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }
}

.dropdown__item_all {
  @extend .dropdown__item;
}

.filter_options__Dropdown__bottom {
  padding-bottom: 8px;
  background-color: $white;
  border-radius: 16px;
}
