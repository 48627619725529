@import '../../../../scss/fonts';
@import '../../../../scss/mixins';
@import '../../../../scss/colors';
@import '../../../../scss/variables';

.gatedsubscribe-forms{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5rem;
    &.side-pannel{
      padding: 0;
      .content-wrapper{
        padding: 2.75rem 1.75rem 1.75rem;
        h2{
          font-family: 'Inter', Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
          font-size: 1.5rem;
          line-height: 1.75rem;
          font-weight: 400;
          margin-bottom: 1rem;
        }
        @include respond-below(md){
          h2{
            font-family: 'Inter', Helvetica, Arial, 'Droid Sans', 'Arimo', sans-serif;
            font-size: 1.25rem;
            line-height: 1.5rem;
            font-weight: 400;
            margin-bottom: 1rem;
          }
        }
      }
    }
    @include mobile{
      padding: 3.75rem 1.25rem;
    }
    .gated-lg-6{
      padding: 0;
    }
    .gated-form{
      padding: 4.25rem 3rem 3rem;
      @include mobile{
        padding: 3rem 1.25rem 2.5rem 1.25rem;
      }
      h2{
        padding-bottom: 0.75rem;
        font-size: 2.25rem;
        line-height: 42px; /* 116.667% */
        letter-spacing: -1px;
        @include mobile{
          font-size: 1.75rem;
          line-height: 34px; /* 121.429% */
          letter-spacing: -0.5px;
        }
      }
      .form-block{
        padding-bottom: 1.25rem !important;
      }
    }
    .title, .sub-title{
      margin-bottom: 1rem;
    }
    .content-wrapper {
      background: #fff;
      border-radius: .75rem;
      min-width: 100%;
      margin: 0;
      //padding: 2rem 2rem 2rem;
      &.bk-violet{
        background-color: $form-background-primary;
      }
      &.bk-green{
        background-color: $form-background-green;
      }
      &.bk-Blue{
        background-color: $form-background-blue;
      }
      &.bk-blue{
        background-color: $form-background-blue;
      }
      &.bk-secondary{
        background-color: $form-background-secondary;
      }
      &.bk-primary{
        background-color: $form-white-color;
      }
      .panel-title{
        color: $form-white-color;
        font-weight: 400;
      }
      .panel-sub-title{
        color: $form-white-color;
        font-size: 1rem;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 400;
        line-height: 1.375rem;
        margin-bottom: 1rem;
      }
      .panel-policy{
        color: $form-white-color;
      }
      .panel-title-black{
        color: $form-background-secondary;
        font-weight: 400;
      }
      .panel-sub-title-black{
        color: $form-background-secondary;
      }
      
      .panel-policy-black{
        color: $form-background-secondary;
        margin-bottom: 0rem;
        padding-bottom:0rem ;
        .checkbox label{
          padding-left:2rem !important;
        }
      }
      .form-wrapper{
        .form-block{
          margin-bottom: 0px;
          .hs-input{
            height: 2.875rem;
            margin-bottom: 1.75rem;
          }
          .submitted-message{
            color:$form-white-color;
          }
          .submitted-message-black{
            color:$form-background-secondary;
          }
        }
        .cta{
          margin-bottom: 1rem;
          button{
            font-size: 1.25rem;
          }
          @include mobile{
            padding-top: 0.25rem;
          }
        }
      }
    }
    .left {
      justify-content: flex-start;
    }
    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
 
    @media (min-width: 1400px){
      .form-section {
          padding: 5rem 0;
      }
   
      .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
}
.gatedsubscribe-forms .content-wrapper {
  @include respond-below(sm) {
    margin-bottom: 60px;
  }
}
.privacy-policy .form-block.checkbox label {
  &.violet,
  &.secondary {
    div {
      font-size: 12px;
      color: $white;
      padding-top: 1px;
    }
  }
  div {
    font-size: 12px;
    padding-top: 1px;
  }
}

