section {
    .banner {
        .ts__light {
            @include bg-mixin("light"){
                background-color: white;
            }
        }
        .ts__dark {
            @include bg-mixin("dark") {
                background-color: black;
            }
        }
    }
}